<template>
  <div class="card">
    <button
      class="thumb btn rounded-0 w-100 border-0 p-2 text-start"
      type="button"
      @click="emit('select')"
    >
      <div class="d-flex justify-content-between align-items-center gap-1">
        <div class="flex-shrink-0 h4 text-secondary" :class="statusIconClass" />
        <div class="flex-grow-1 ms-1 me-2 mw-0 lh-md">
          <div class="text-secondary">{{ questionSetTitle }}</div>
          <div v-if="measureDescription" class="text-secondary">{{ measureDescription }}</div>
          <div
            v-for="description of nonComplianceDescriptions"
            :key="description"
            class="fw-normal small"
          >
            {{ description }}
          </div>
          <div v-if="isComplete" class="text-secondary fw-normal small">
            Completed {{ formatISOStringDate(completedDateTime) }}
          </div>
        </div>
        <div class="flex-shrink-0">
          <i class="bi-chevron-right" />
        </div>
      </div>
    </button>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>
<script setup>
import { computed } from 'vue';
import { formatISOStringDate } from '../utilities/date';

const emit = defineEmits(['select']);

const props = defineProps({
  questionSetTitle: {
    type: String,
    required: true,
  },
  measureDescription: {
    type: String,
    default: '',
  },
  hasIncompleteReason: {
    type: Boolean,
    default: false,
  },
  isComplete: {
    type: Boolean,
    default: false,
  },
  completedDateTime: {
    type: String,
    default: '',
  },
  isNonCompliance: {
    type: Boolean,
    default: false,
  },
  nonComplianceDescriptions: {
    type: Array,
    default: () => [],
  },
});

const statusIconClass = computed(() => {
  if (props.isNonCompliance) return 'bi-x-circle text-danger';
  if (props.isComplete) return 'bi-check-circle text-success';
  return 'bi-question-circle text-secondary';
});
</script>

<style lang="scss" scoped>
.thumb {
  transition: 100ms ease box-shadow, 100ms ease border-color;
  &:hover {
    border-color: $gray-500;
    box-shadow: $box-shadow-sm;
  }
}

.progress {
  position: relative;
  height: 3px;
  background-color: $gray-200;
  overflow: hidden;
}
</style>
