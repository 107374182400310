<template>
  <RouterLink class="remediation-thumb" :to="link">
    <div class="d-flex align-items-center">
      <div class="ps-2 pe-1 overflow-hidden">
        <div class="fs-6 fw-bold text-secondary">
          {{ projectReference }}
        </div>
        <div v-if="address1" class="text-secondary fs-6 text-nowrap text-truncate">
          {{ address1 }}, {{ postcode }}
        </div>
        <div v-if="deadline" class="text-secondary fs-6 text-nowrap text-truncate">
          {{ deadline }}
        </div>
      </div>
      <div class="ms-auto d-flex flex-column align-items-center flex-shrink-0 gap-1">
        <StatusBadge
          v-if="status !== 'Has Evidence' && status !== 'Needs Evidence'"
          :status="status"
          small
        />
        <StatusBadge v-if="onsiteStatus" :status="'Onsite: ' + onsiteStatus" small />
        <StatusBadge v-if="desktopStatus" :status="'Desktop: ' + desktopStatus" small />
      </div>
    </div>
  </RouterLink>
</template>

<script setup>
import { differenceInDays } from 'date-fns';
import { computed } from 'vue';
import StatusBadge from './StatusBadge.vue';

const props = defineProps({
  id: { type: String, required: true },
  projectId: { type: String, required: true },
  lodgementId: { type: String, default: undefined },
  projectReference: { type: String, required: true },
  severity: { type: String, required: true },
  status: { type: String, required: true },
  onsiteStatus: { type: String, required: true },
  desktopStatus: { type: String, required: true },
  escalationDateTime: { type: Date, default: undefined },
  address1: { type: String, default: undefined },
  postcode: { type: String, default: undefined },
});

const deadline = computed(() => {
  if (!props.escalationDateTime) return undefined;
  const days = differenceInDays(props.escalationDateTime, new Date());
  if (days === 0) {
    return '< 1 day before escalation';
  }
  return `${days} day${days === 1 ? '' : 's'} before escalation`;
});

const link = computed(() => {
  if (props.lodgementId) {
    return {
      name: 'lodgement-standalone-remediation',
      params: { projectId: props.projectId, lodgementId: props.lodgementId },
    };
  }
  return { name: 'project-remediation', params: { projectId: props.projectId } };
});
</script>

<style lang="scss" scoped>
.remediation-thumb {
  background-color: $light;
  border-radius: 0.25rem;
  border: 1px solid $border-color;
  display: block;
  overflow: hidden;
  padding: 0.5rem;
  text-decoration: none;
  transition: 200ms ease box-shadow;
  &:hover {
    box-shadow: $box-shadow-sm;
  }
}
</style>
