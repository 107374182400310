<template>
  <DrawerOverlay title="Non-Compliance Answers" @dismiss="emit('dismiss')">
    <div class="border-top mt-2">
      <div v-for="f of nonComplianceForms" :key="f.auditFormId" class="audit-section">
        <div class="d-flex align-items-center justify-content-between gap-2">
          <h5>{{ f.measureDescription || f.questionSetTitle }}</h5>
          <StatusBadge :status="f.nonComplianceSeverity" small />
        </div>
        <h6 class="text-secondary">{{ f.title }}</h6>
        <div class="mt-2 d-flex flex-column gap-3">
          <div
            v-for="questionAnswer of f.nonComplianceAnswers"
            class="card qa-group"
            :key="questionAnswer.id"
          >
            <div class="card-header p-2">
              {{ questionAnswer.number }}. {{ questionAnswer.question }}
            </div>
            <div class="card-body p-2 d-flex flex-column gap-2">
              <FormWrapper label="Answer">
                <div class="answer">{{ questionAnswer.answer }}</div>
              </FormWrapper>
              <FormWrapper label="Severity">
                <div class="answer">
                  {{ questionAnswer.severity }}
                </div>
              </FormWrapper>
              <FormWrapper label="Description">
                <div class="answer">{{ questionAnswer.description }}</div>
              </FormWrapper>
            </div>
          </div>
        </div>
      </div>
      <div class="audit-section">
        <button class="btn btn-outline-primary w-100" type="button" @click="emit('dismiss')">
          Return to Overview
        </button>
      </div>
    </div>
  </DrawerOverlay>
</template>

<script setup>
import { computed } from 'vue';
import { mapSeverity } from '../utilities/compliance';
import DrawerOverlay from './DrawerOverlay.vue';
import StatusBadge from './StatusBadge.vue';
import FormWrapper from './FormWrapper.vue';

const emit = defineEmits(['confirm', 'dismiss']);
const props = defineProps({
  forms: {
    type: Array,
    default: () => [],
  },
  measures: {
    type: Array,
    default: () => [],
  },
});

const nonComplianceForms = computed(() => {
  let i = 0;
  const mapAnswer = (questionAnswer) => {
    i++;
    return {
      id: questionAnswer.questionId,
      question: questionAnswer.text,
      answer:
        questionAnswer.type === 'File'
          ? JSON.parse(questionAnswer.answer || 'null')?.title
          : questionAnswer.answer,
      number: i,
      severity: mapSeverity(questionAnswer.nonComplianceSeverity),
      description: questionAnswer.nonComplianceDescription,
    };
  };
  return props.forms
    .filter((f) => f.nonComplianceAnswers?.length > 0)
    .map((f) => ({
      ...f,
      nonComplianceAnswers: f.nonComplianceAnswers.map(mapAnswer),
    }));
});
</script>

<style lang="scss" scoped>
.qa-group {
  line-height: $line-height-sm;
}

.qa-group .card-header {
  font-size: $font-size-sm;
  font-weight: $form-label-font-weight;
}
.qa-group .answer {
  font-size: $font-size-sm;
  color: $secondary;
}
.qa-group:deep(.form-label) {
  margin-bottom: 0.1875rem;
}
</style>
