<template>
  <div class="audit-section">
    <div class="form-label">Actions</div>
    <button class="ms-auto btn btn-primary btn-sm" type="button" @click.prevent="emit('select')">
      New Audit
    </button>
  </div>

  <div v-if="!newonly">
    <div class="audit-section">
      <div class="form-label mb-0">Ongoing Audits</div>
      <div class="mt-2 d-flex flex-column gap-2">
        <AuditDesktopThumb
          v-for="audit of ongoing"
          :key="audit.auditId"
          v-bind="audit"
          @select="emit('select', audit)"
        />
        <div v-if="!ongoing.length" class="fs-6 text-secondary">No ongoing audits found.</div>
      </div>
    </div>
    <div class="audit-section">
      <div class="form-label mb-0">Unresolved Audits</div>
      <div class="mt-2 d-flex flex-column gap-2">
        <AuditDesktopThumb
          v-for="audit of unresolved"
          :key="audit.auditId"
          v-bind="audit"
          @select="emit('select', audit)"
        />
        <div v-if="!unresolved.length" class="fs-6 text-secondary">No unresolved audits found.</div>
      </div>
    </div>
    <div class="audit-section">
      <div class="form-label mb-0">Resolved Audits</div>
      <div class="mt-2 d-flex flex-column gap-2">
        <AuditDesktopThumb
          v-for="audit of resolved"
          :key="audit.auditId"
          v-bind="audit"
          @select="emit('select', audit)"
        />
        <div v-if="!resolved.length" class="fs-6 text-secondary">No resolved audits found.</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import AuditDesktopThumb from './AuditDesktopThumb.vue';

const emit = defineEmits(['select']);

const props = defineProps({
  audits: {
    type: Array,
    required: true,
  },
  newonly: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const isResolved = (audit) => audit.isResolved || audit.isPass || audit.outcome === 'Pass';
const ongoing = computed(() => props.audits.filter((a) => !a.isComplete));
const unresolved = computed(() => props.audits.filter((a) => a.isComplete && !isResolved(a)));
const resolved = computed(() => props.audits.filter((a) => a.isComplete && isResolved(a)));
</script>
