import { watchOnce } from '@vueuse/core';
import { computed, ref, watch } from 'vue';

export const useAsyncSavedState = (s, fn, labels = {}, start = true) => {
  let activated = false;
  const state = ref(s);
  const changed = ref(false);
  const loading = ref(false);
  const clean = ref(true);

  const label = computed(() => {
    if (clean.value) return labels.clean || 'No Changes';
    if (loading.value) return labels.loading || 'Saving';
    if (changed.value) return labels.changed || 'Save';
    return labels.saved || 'Saved';
  });

  const save = async (...args) => {
    if (!activated) throw new Error('State not yet activate');
    try {
      loading.value = true;
      const result = await fn(state.value, ...args);
      changed.value = false;
      return result;
    } finally {
      loading.value = false;
    }
  };

  const activate = (s = undefined) => {
    if (activated) throw new Error('State already activated');
    if (s !== undefined) state.value = s;
    watchOnce(state, () => (clean.value = false), { deep: true });
    watch(state, () => (changed.value = true), { deep: true });
    activated = true;
  };
  if (start) activate();
  return { state, clean, changed, loading, label, save, activate };
};
