<template>
  <div
    v-if="(inReview || !form.isComplete) && questions.length"
    class="d-flex flex-column flex-grow-1 mh-100"
  >
    <Questionnaire
      :title="props.form.questionSetTitle"
      :subtitle="props.form.measureDescription"
      :questions="visibleQuestions"
      :disabled="!inReview && props.form.isComplete"
      wrap-class="audit-section flex-grow-1 d-flex flex-column"
      :can-complete="canComplete"
      @review="reviewQuestionnaire"
      @update:answer="onAnswer"
      @update:index="onIndexUpdate"
    >
      <template #header="{ currentIndex, hasAnswer }">
        <div class="px-3 py-2 d-flex justify-content-between align-items-center">
          <button
            class="btn p-0 text-primary bi-chevron-left"
            type="button"
            @click="emit('dismiss')"
          >
            Close
          </button>
          <div :class="hasAnswer ? 'text-success' : 'text-secondary'">
            <i v-if="hasAnswer" class="bi-check-circle-fill me-1" />
            {{ `${currentIndex + 1} of ${visibleQuestions.length}` }}
          </div>
        </div>
      </template>
      <template #footer>
        <div class="audit-section d-flex flex-column gap-2">
          <button
            class="w-100 btn btn-outline-primary"
            type="button"
            :disabled="!changed || isSaving"
            @click.prevent="save"
          >
            {{ saveStatus }}
            <span v-if="isSaving" class="ms-1 spinner-border spinner-border-sm align-text-bottom" />
          </button>
          <button
            class="w-100 btn btn-primary"
            type="button"
            :disabled="!canComplete"
            @click.prevent="reviewQuestionnaire"
          >
            Review &amp; Complete
          </button>
        </div>
      </template>
    </Questionnaire>
    <Transition name="swipe-right">
      <AuditOnsiteQuestionnaireConfirm
        v-if="showReview"
        :questions="visibleQuestions"
        @dismiss="showReview = false"
        @complete="onComplete"
      />
    </Transition>
  </div>
  <div
    v-else-if="form.isComplete && questions.length"
    class="d-flex flex-column flex-grow-1 mh-100"
  >
    <div class="px-3 py-2 d-flex justify-content-between align-items-center">
      <button class="btn p-0 text-primary bi-chevron-left" type="button" @click="emit('dismiss')">
        Close
      </button>
    </div>
    <AuditOnsiteQuestionnaireReadonly
      :questions="visibleQuestions"
      :can-restart="props.form.canRestart"
      @restart="emit('restart', { form: props.form })"
    />
  </div>
  <div v-else class="p-5 text-center">
    <span class="spinner-border" />
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useDebounceFn } from '@vueuse/core';
import { useApi } from '../stores/api';
import { useAsyncSavedState } from '../utilities/state';
import AuditOnsiteQuestionnaireConfirm from './AuditOnsiteQuestionnaireConfirm.vue';
import Questionnaire from './Questionnaire.vue';
import AuditOnsiteQuestionnaireReadonly from './AuditOnsiteQuestionnaireReadonly.vue';
import { questionHasAnswer } from '../utilities/compliance';

const emit = defineEmits(['complete', 'dismiss', 'restart']);

const props = defineProps({
  project: {
    type: Object,
    required: true,
  },
  form: {
    type: Object,
    required: true,
  },
  audit: {
    type: Object,
    required: true,
  },
});

const api = useApi();

const {
  state: questions,
  save,
  loading: isSaving,
  changed,
  label: saveStatus,
  activate: activateQuestionState,
} = useAsyncSavedState(
  [],
  async (questions) => {
    await api.silently().updateAuditForm(props.form.auditFormId, {
      questions: questions.map((q) => ({
        questionId: q.questionId,
        answer: q.answer,
      })),
    });
  },
  {
    changed: 'Save Progress',
    saved: 'Progress Saved',
  },
  false
);

const inReview = computed(() => props.audit?.status === 'Review');

const onAnswer = ({ id, answer }) => {
  const question = questions.value.find((q) => q.questionId === id);
  question.answer = answer;
};

// @TODO check this does not introduce a race condition
const onIndexUpdate = useDebounceFn(() => {
  if (!changed.value) return;
  save();
}, 250);

const showReview = ref(false);
const onComplete = async () => {
  showReview.value = false;
  await api.updateAuditForm(props.form.auditFormId, {
    questions: questions.value.map((q) => ({
      questionId: q.questionId,
      answer: q.answer,
    })),
    isComplete: true,
  });
  emit('complete');
};

const isVisibleQuestion = (question) => {
  if (question.isConditional) {
    const conditionQuestion = questions.value.find(
      (q) => q.questionId === question.conditionQuestionId
    );
    return conditionQuestion?.answer === question.conditionAnswer;
  }
  return true;
};

const visibleQuestions = computed(() => questions.value.filter(isVisibleQuestion));

const canComplete = computed(() =>
  questions.value.every((question) => {
    if (isVisibleQuestion(question)) {
      return questionHasAnswer(question);
    }
    return true;
  })
);

const reviewQuestionnaire = () => {
  if (canComplete.value) {
    showReview.value = true;
  }
};

const init = async () => {
  const api = useApi();
  const result = await api
    .silently()
    .getAuditForm(props.project.retrofitProjectId, props.form.auditFormId);

  result.model?.questions?.sort((a, b) => {
    return (a.order || 0) - (b.order || 0);
  });
  activateQuestionState(result.model?.questions);
};
onMounted(init);
</script>

<style lang="scss">
.question-next-enter-active,
.question-next-leave-active,
.question-prev-enter-active,
.question-prev-leave-active {
  transition: opacity 100ms ease, 100ms ease transform;
}
.question-next-enter-from {
  transform: translateX(-1em);
  opacity: 0;
}
.question-next-leave-to {
  transform: translateX(1em);
  opacity: 0;
}
.question-prev-enter-from {
  transform: translateX(1em);
  opacity: 0;
}
.question-prev-leave-to {
  transform: translateX(-1em);
  opacity: 0;
}
</style>
