import { useLocalStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { getMatrix } from '../stores/app';
import { recentSerializer } from '../utilities/recent';

export const isLodgementWorkStyle = (checkType, lodgementType = '') => {
  const matrix = getMatrix(lodgementType);
  return matrix?.lodgementOfWorkStyle.toUpperCase() === checkType.toUpperCase();
};

export const idKey = 'lodgementId';

export const toRecent = (lodgement, projectId) => {
  const id = lodgement[idKey];
  const title = lodgement.certificateNumber || lodgement.retrofitProjectReference;
  const status = lodgement.status;
  const to = {
    name: isLodgementWorkStyle('project', lodgement.lodgementType)
      ? 'lodgement'
      : 'lodgement-standalone',
    params: { lodgementId: id, projectId },
  };
  return { id, title, status, to };
};

export const useLodgementsStore = defineStore({
  id: 'lodgements',
  state: () => ({
    lodgement: null,
    lodgements: null,
    lodgementsCache: {},
    searchParams: {
      term: '',
      pageSize: 12,
      page: 0,
      totalPages: 0,
      totalItems: 0,
    },
    recentlyViewed: useLocalStorage('lodgements-recent', [], {
      serializer: recentSerializer,
    }),
  }),
  getters: {
    isComplete: (state) => !!(state.lodgement?.status === 'Complete'),
    isStandaloneLodgement: (state) =>
      isLodgementWorkStyle('standalone', state.lodgement.lodgementType),
    isProjectLodgement: (state) => isLodgementWorkStyle('project', state.lodgement.lodgementType),
  },
  // Individual
  actions: {
    get(id) {
      return this.lodgementsCache[id];
    },
    cache(id, lodgement) {
      this.lodgementsCache[id] = lodgement;
      return this;
    },
    set(id) {
      const cached = this.lodgementsCache[id];
      if (!cached) throw new Error(`Lodgement with ID “${id}” not found`);
      this.lodgement = cached;
      return this;
    },
    unset() {
      this.lodgement = null;
      return this;
    },
    unlist(id) {
      this.lodgements = this.lodgements?.filter((lodgement) => lodgement[idKey] !== id) || null;
      return this;
    },
    uncache(id) {
      delete this.lodgementsCache[id];
      return this;
    },
    remove(id) {
      this.uncache(id);
      this.unlist(id);
      this.removeRecent(id);
      return this;
    },
    // List
    setList(lodgements) {
      this.lodgements = lodgements;
      this.refreshRecents(lodgements);
      return this;
    },
    // Recent
    addRecent(id, projectId) {
      const lodgement = this.get(id);
      if (lodgement) {
        // If it already exists in the array, remove it
        this.removeRecent(id);
        // Push entry to the start of the array
        this.recentlyViewed.unshift(toRecent(lodgement, projectId));
        // Limit the number of results
        const maxLength = 3;
        this.recentlyViewed.length = Math.min(this.recentlyViewed.length, maxLength);
      }
      return this;
    },
    removeRecent(id) {
      const index = this.recentlyViewed.findIndex((recent) => recent.id === id);
      if (index > -1) this.recentlyViewed.splice(index, 1);
      return this;
    },
    refreshRecents(lodgements) {
      this.recentlyViewed.forEach((recent) => {
        const match = lodgements.find((l) => l[idKey] === recent.id);
        if (match) recent = toRecent(match);
      });
      return this;
    },
    clearRecents() {
      this.recentlyViewed.splice(0);
    },
  },
});
