<template>
  <LayoutAuth title="Two-Factor Authentication" :form="props.form" :rules="rules">
    <template #header>
      <p class="text-secondary lh-sm mt-2">
        <small>
          Your account is protected by two-factor authentication. We've just sent a text message to
          {{ auth.mobileNumber }}.
        </small>
      </p>
    </template>
    <template #default="{ v$, submitStage, busy, submitButton }">
      <form @submit.prevent="submitStage(handleSubmit)">
        <FormInput
          class="mt-4"
          label="Received SMS code"
          id="smsCode"
          type="text"
          :validator="v$.smsCode"
          :disabled="busy"
          required
          v-model="smsCode"
        />
        <div class="mt-4 d-flex justify-content-between">
          <Component :is="submitButton">Verify Code</Component>
        </div>
      </form>
    </template>
    <template #footer>
      <RouterLink :to="{ name: 'login' }" class="text-body" @click.prevent="onBack"
        >I didn’t receive a code</RouterLink
      >
      <span class="mx-2">•</span>
      <RouterLink :to="{ name: 'login' }" class="text-body" @click.prevent="onReset"
        >Back to Login</RouterLink
      >
    </template>
  </LayoutAuth>
</template>

<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { required, helpers } from '@vuelidate/validators';
import FormInput from './FormInput.vue';
import LayoutAuth from './LayoutAuth.vue';
import { useAuthStore } from '../stores/auth';
import { useAuthForm, formEmit, formProp } from '../utilities/auth';

const emit = defineEmits([formEmit]);
const props = defineProps(formProp);

const { smsCode } = useAuthForm(props, emit, ['smsCode']);

const rules = computed(() => ({
  smsCode: {
    required: helpers.withMessage('SMS code is required', required),
  },
}));

const auth = useAuthStore();
const router = useRouter();

const handleSubmit = async (form) => {
  await auth.verifySmsCode(form.smsCode);
  await auth.login({
    username: form.email,
    password: form.password,
  });
  router.push({ name: 'dashboard' });
};

const onBack = () => {
  auth.smsCodeStatus = 'required';
};
const onReset = () => {
  auth.$reset();
};
</script>
