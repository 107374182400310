<template>
  <div class="px-3 py-3 border-top">
    <Component
      :is="link ? 'RouterLink' : 'div'"
      class="d-flex justify-content-start gap-1 align-items-center text-decoration-none"
      :to="link"
    >
      <span class="form-label mb-0">{{ title }}</span>
      <span class="text-secondary fs-xxs">({{ audits.length }})</span>
    </Component>
    <div v-if="audits.length" class="mt-2 d-flex flex-column gap-2">
      <AuditDesktopThumb
        v-for="audit of audits"
        :key="audit.auditId"
        v-bind="audit"
        @select="onView(audit)"
      />
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import AuditDesktopThumb from './AuditDesktopThumb.vue';

defineProps({
  title: {
    type: String,
    default: '',
  },
  link: {
    type: [Object, String],
    default: null,
  },
  audits: {
    type: Array,
    default: () => [],
  },
});

const router = useRouter();
const onView = (audit) => {
  router.push({
    name: audit.auditingWhat,
    params: {
      measureId: audit.whatMeasureId,
      lodgementId: audit.whatLodgementId,
      designId: audit.whatRetrofitDesignId,
      optionId: audit.whatImprovementOptionEvaluationId,
    },
    query: {
      audit: audit.auditId,
      returnTo: audit.whatLodgementId ? 'project-lodgements' : undefined,
    },
  });
};
</script>
