<template>
  <div v-if="loaded" class="drawer-content">
    <div v-if="allLegacyAudits?.length === 0">
      <KeepAlive include="AuditDesktopOverview">
        <AuditDesktopAuditor
          v-if="!audit.desktop.auditor"
          :project="props.project"
          @update="assignAuditor"
        />
        <AuditDesktopQuestionnaire
          v-else-if="activeForm"
          :project="project"
          :form="activeForm"
          :audit="audit"
          @dismiss="activeForm = null"
          @complete="onFormComplete"
          :can-restart="false"
        />
        <AuditDesktopOverview
          v-else
          :project="props.project"
          :lodgement="props.lodgement"
          @form="selectForm"
          @add-form="addFormToAudit"
          @complete="moveToReview"
          @review-complete="reviewCompleteAudit"
          @admin-move-to-review="adminMoveToReview"
          @clear-audit="clearAudit"
        />
      </KeepAlive>
    </div>
    <div v-else>      
      <div v-if="!noaccess && !escalatedToAssurance" class="audit-section">
        <FormWrapper label="Project Level Actions">
          <div class="d-flex flex-column gap-2">
            <button
              class="btn btn-outline-primary w-100"
              type="button"
              @click.prevent="state.showingNoAccessFlag = true"
            >
              Flag No Access
            </button>
            <button
              class="btn btn-outline-primary w-100"
              type="button"
              @click.prevent="state.showingEscalateToAssurance = true"
            >
              Escalate to Assurance
            </button>
          </div>
        </FormWrapper>
      </div>
      <AuditDesktopSection
        v-if="isSummary && canAudit && auditingWhat === 'lodgement-standalone'"
        :newonly="true"
        :audits="[]"
        @select="setAudit"
      />      
      <AuditDesktopSummary
        v-if="isSummary"
        :audits="legacyAudits"
        :auditable-sections="auditableSections"
        :project="project"
        :noaccess="noaccess"
        :escalated-to-assurance="escalatedToAssurance"
        @remove-no-access="confirmOnRemoveNoAccess"
        @resolve-no-access="confirmOnResolveNoAccess"
        @remove-escalate-to-assurance="confirmOnRemoveEscalateToAssurance"
        @resolve-escalate-to-assurance="confirmOnResolveEscalateToAssurance"
      />
      <AuditDesktopSection v-else-if="canAudit" :audits="legacyAudits" @select="setAudit" />
      <div v-else>
        <div class="px-3 py-3 text-center">Auditing not available here.</div>
      </div>
      <Transition name="swipe-right">
        <AuditDesktopForm
          v-if="showForm"
          :project="project"
          :lodgement="lodgement"
          :section="auditSection"
          :sections="auditableSections"
          :audit="currentAudit"
          :audits="legacyAudits"
          :type="auditingWhat"
          @create="onCreateAudit"
          @update="onUpdateAudit"
          @delete="onDeleteAudit"
          @resolve="onResolveAudit"
          @dismiss="showForm = false"
        />        
        <AuditDesktopNoAccess
          v-else-if="state.showingNoAccessFlag"
          :audit="audit.desktop"
          @confirm="onConfirmNoAccess"
          @dismiss="state.showingNoAccessFlag = false"
        />
        <AuditDesktopEscalateToAssurance
          v-else-if="state.showingEscalateToAssurance"
          :audit="audit.desktop"
          @confirm="onConfirmEscalateToAssurance"
          @dismiss="state.showingEscalateToAssurance = false"
        />
      </Transition>
    </div>
  </div>
  <div v-else class="drawer-content p-5 text-center">
    <span class="spinner-border" />
  </div>
  
  <Teleport to="body">
    <Modal ref="confirmRemoveNoAccessModalEl" title="Remove No Access" save-text="Remove No Access">
      <template #form
        >Are you sure you want to remove No Access? This cannot be reversed.</template
      >
      <template #footer>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" @click.prevent="onRemoveNoAccess()">
          Remove No Access
        </button>
      </template>
    </Modal>
    <Modal ref="confirmResolveNoAccessModalEl" title="Resolve No Access" save-text="Resolve No Access">
      <template #form
        >Are you sure you want to resolve No Access? This cannot be reversed.</template
      >
      <template #footer>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" @click.prevent="onResolveNoAccess()">
          Resolve No Access
        </button>
      </template>
    </Modal>
    <Modal ref="confirmRemoveEscalateToAssuranceModalEl" title="Remove Escalate to Assurance" save-text="Remove Escalate to Assurance">
      <template #form
        >Are you sure you want to remove Escalate to Assurance? This cannot be reversed.</template
      >
      <template #footer>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" @click.prevent="onRemoveEscalateToAssurance()">
          Remove Escalate to Assurance
        </button>
      </template>
    </Modal>
    <Modal ref="confirmResolveEscalateToAssuranceModalEl" title="Resolve Escalate to Assurance" save-text="Resolve Escalate to Assurance">
      <template #form
        >Are you sure you want to resolve Escalate to Assurance? This cannot be reversed.</template
      >
      <template #footer>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" @click.prevent="onResolveEscalateToAssurance()">
          Resolve Escalate to Assurance
        </button>
      </template>
    </Modal>
  </Teleport>
</template>

<script setup>
import { ref, computed, onBeforeMount, watch, reactive } from 'vue';
import { useRoute } from 'vue-router';
import { sortBy } from 'lodash-es';
import AuditDesktopForm from './AuditDesktopForm.vue';
import AuditDesktopSection from './AuditDesktopSection.vue';
import AuditDesktopSummary from './AuditDesktopSummary.vue';
import { useApi } from '../stores/api';
import AuditDesktopAuditor from './AuditDesktopAuditor.vue';
import AuditDesktopOverview from './AuditDesktopOverview.vue';
import AuditDesktopQuestionnaire from './AuditDesktopQuestionnaire.vue';
import { useAuditStore } from '../stores/audit';
import { useProjectsStore } from '../stores/projects';
import { showNotification } from '../utilities/notification';
import AuditDesktopNoAccess from './AuditDesktopNoAccess.vue';
import AuditDesktopEscalateToAssurance from './AuditDesktopEscalateToAssurance.vue';
import FormWrapper from './FormWrapper.vue';
import Modal, { useModal } from './Modal.vue';

const props = defineProps({
  project: {
    type: Object,
    required: true,
  },
  lodgement: {
    type: Object,
    default: null,
  },
  canAudit: {
    type: Boolean,
    default: false,
  },
});

const api = useApi();
const route = useRoute();

// LEGACY DESKTOP AUDIT
const currentAudit = ref({});
const showForm = ref(false);
const setAudit = (audit = null) => {
  currentAudit.value = audit;
  showForm.value = true;
};

const state = reactive({
  showingNoAccessFlag: false,
  showingEscalateToAssurance: false,
});

const auditingWhat = computed(() => (route.meta?.audit ? route.name : ''));
const auditSection = computed(() => {
  const [, ...rest] = auditingWhat.value.split('-') || [];
  return rest.join('-');
});
const isSummary = computed(
  () =>
    ((auditingWhat.value.split('-') || [])[0] === 'project' && !auditSection.value) ||
    auditingWhat.value === 'lodgement-standalone'
);

const allLegacyAudits = ref([]);
const legacyAudits = computed(() => {
  const filtered = isSummary.value
    ? allLegacyAudits.value
    : allLegacyAudits.value.filter(
        (a) =>
          a.auditingWhat === auditingWhat.value &&
          a.whatSection === (auditSection.value || null) &&
          a.whatLodgementId === (route.params.lodgementId || null) &&
          a.whatMeasureId === (route.params.measureId || null)
      );
  return sortBy(filtered, 'auditDate');
});
const noaccess = computed(() => {
  return allLegacyAudits.value.some((a) => a.auditingWhat === 'noaccess' && !a.isResolved) || false;
});
const escalatedToAssurance = computed(() => {
  return allLegacyAudits.value.some((a) => a.auditingWhat === 'escalated-to-assurance' && !a.isResolved) || false;
});

const auditableSections = computed(() => {
  const standalone = props.project.lodgementOfWorkStyle === 'Standalone';
  const sections = allAuditableSections.filter((s) => s.standalone == standalone);
  return sections;
});

const allAuditableSections = [
  {
    name: 'project-info',
    title: 'Information',
    link: { name: 'project-info' },
    summary: true,
    standalone: false,
  },
  {
    name: 'project-assessment',
    title: 'Assessment',
    link: { name: 'project-assessment' },
    summary: true,
    standalone: false,
  },
  {
    name: 'project-property',
    title: 'Property Information & Tenure',
    link: { name: 'project-property' },
    summary: true,
    standalone: false,
  },
  {
    name: 'project-defects',
    title: 'Defects',
    link: { name: 'project-defects' },
    summary: true,
    standalone: false,
  },
  {
    name: 'project-intended-outcomes',
    title: 'Intended Outcomes',
    link: { name: 'project-intended-outcomes' },
    summary: true,
    standalone: false,
  },
  {
    name: 'project-improvement-option',
    title: 'Improvement Option Evaluation',
    link: { name: 'project-evaluation' },
    summary: true,
    standalone: false,
  },
  {
    name: 'project-medium-term-improvement-plan',
    title: 'Medium Term Improvement Plan',
    link: { name: 'project-medium-term-improvement-plan' },
    summary: true,
    standalone: false,
  },
  {
    name: 'project-risk',
    title: 'Risk',
    link: { name: 'project-risk' },
    summary: true,
    standalone: false,
  },
  {
    name: 'project-design-option',
    title: 'Retrofit Design',
    link: { name: 'project-design' },
    summary: true,
    standalone: false,
  },
  {
    name: 'project-documents',
    title: 'Documents',
    link: { name: 'project-documents' },
    summary: true,
    standalone: false,
  },
  {
    name: 'project-roles',
    title: 'Roles',
    link: { name: 'project-roles' },
    summary: true,
    standalone: false,
  },
  {
    name: 'project-notes',
    title: 'Notes',
    link: { name: 'project-notes' },
    summary: true,
    standalone: false,
  },
  {
    name: 'lodgement',
    title: 'Lodgements',
    link: { name: 'project-lodgements' },
    summary: true,
    standalone: false,
  },
  {
    name: 'lodgement-measure',
    title: 'Lodgement Measures',
    standalone: false,
  },
  {
    name: 'lodgement-standalone',
    title: 'Lodgements',
    summary: true,
    standalone: true,
  },
  {
    name: 'lodgement-standalone-measure',
    title: 'Lodgement Measures',
    standalone: true,
  },
];

const onConfirmNoAccess = async ({ comment = '', auditDate = '' }) => {
  updateAuditStatus({ auditingWhat: 'noaccess', auditDate, comment, isPass: false });
};

const onConfirmEscalateToAssurance = async ({ comment = '', auditDate = '' }) => {
  updateAuditStatus({ auditingWhat: 'escalated-to-assurance', auditDate, comment, isPass: false });
};

const { modal: confirmRemoveNoAccessModal, el: confirmRemoveNoAccessModalEl } = useModal();
const { modal: confirmResolveNoAccessModal, el: confirmResolveNoAccessModalEl } = useModal();
const { modal: confirmRemoveEscalateToAssuranceModal, el: confirmRemoveEscalateToAssuranceModalEl } = useModal();
const { modal: confirmResolveEscalateToAssuranceModal, el: confirmResolveEscalateToAssuranceModalEl } = useModal();

const confirmOnRemoveNoAccess = async () => {
  confirmRemoveNoAccessModal.value.show();
};

const confirmOnResolveNoAccess = async () => {
  confirmResolveNoAccessModal.value.show();
};

const confirmOnRemoveEscalateToAssurance = async () => {
  confirmRemoveEscalateToAssuranceModal.value.show();
};

const confirmOnResolveEscalateToAssurance = async () => {
  confirmResolveEscalateToAssuranceModal.value.show();
};


const onRemoveNoAccess = async () => {
  const noAccessAudit = allLegacyAudits.value.find((a) => a.auditingWhat === 'noaccess');
  const result = await api.deleteAudit(props.project.retrofitProjectId, noAccessAudit.auditId, {});
  if (result) {
    const index = allLegacyAudits.value.findIndex((a) => a.auditId === noAccessAudit.auditId);
    if (index > -1) {
      allLegacyAudits.value.splice(index, 1);
    }
  }
  confirmRemoveNoAccessModal.value.hide();
};
const onResolveNoAccess = async () => {
  const noAccessAudit = allLegacyAudits.value.find((a) => a.auditingWhat === 'noaccess');
  const result = await api.resolveAudit(props.project.retrofitProjectId, {
    auditId: noAccessAudit.auditId,
    isResolved: true,
    resolvedDt: new Date().toISOString(),
  });
  if (result) {
    const index = allLegacyAudits.value.findIndex((a) => a.auditId === result.model.auditId);
    if (index > -1) {
      allLegacyAudits.value[index] = result.model;
      showForm.value = false;
    }
  }
  confirmResolveNoAccessModal.value.hide();
};

const onRemoveEscalateToAssurance = async () => {
  const noAccessAudit = allLegacyAudits.value.find((a) => a.auditingWhat === 'escalated-to-assurance');
  const result = await api.deleteAudit(props.project.retrofitProjectId, noAccessAudit.auditId, {});
  if (result) {
    const index = allLegacyAudits.value.findIndex((a) => a.auditId === noAccessAudit.auditId);
    if (index > -1) {
      allLegacyAudits.value.splice(index, 1);
    }
  }
  confirmRemoveEscalateToAssuranceModal.value.hide();
};
const onResolveEscalateToAssurance = async () => {
  const noAccessAudit = allLegacyAudits.value.find((a) => a.auditingWhat === 'escalated-to-assurance');
  const result = await api.resolveAudit(props.project.retrofitProjectId, {
    auditId: noAccessAudit.auditId,
    isResolved: true,
    resolvedDt: new Date().toISOString(),
  });
  if (result) {
    const index = allLegacyAudits.value.findIndex((a) => a.auditId === result.model.auditId);
    if (index > -1) {
      allLegacyAudits.value[index] = result.model;
      showForm.value = false;
    }
  }
  confirmResolveEscalateToAssuranceModal.value.hide();
};

const onCreateAudit = async (form) => {
  const result = await api.createAudit(props.project.retrofitProjectId, form);
  if (result) {
    allLegacyAudits.value.unshift(result.model);
    showForm.value = false;
  }
};
const onUpdateAudit = async (form) => {
  const result = await api.updateAudit(props.project.retrofitProjectId, form);
  if (result) {
    const index = allLegacyAudits.value.findIndex((a) => a.auditId === result.model.auditId);
    if (index > -1) {
      allLegacyAudits.value[index] = result.model;
      showForm.value = false;
    }
  }
};
const updateAuditStatus = async (updates) => {
  const result = await api.createAudit(props.project.retrofitProjectId, {
    retrofitProjectId: props.project.retrofitProjectId,
    projectReference: props.project.yourProjectReference,
    ...updates,
  });
  if (result) {
    allLegacyAudits.value.unshift(result.model);
  }
  state.showingNoAccessFlag = false;
  state.showingEscalateToAssurance = false;
};
const onDeleteAudit = async ({
  retrofitProjectId,
  auditId,
  whatLodgementId,
  whatMeasureId,
  whatSupportingDocumentId,
}) => {
  const params = {
    lodgementId: whatLodgementId,
    measureId: whatMeasureId,
    documentId: whatSupportingDocumentId,
  };
  const result = await api.deleteAudit(retrofitProjectId, auditId, params);
  if (result) {
    const index = allLegacyAudits.value.findIndex((a) => a.auditId === auditId);
    if (index > -1) {
      allLegacyAudits.value.splice(index, 1);
      showForm.value = false;
    }
  }
};
const onResolveAudit = async ({ payload, next }) => {
  const result = await api.resolveAudit(props.project.retrofitProjectId, payload);
  if (result) {
    const index = allLegacyAudits.value.findIndex((a) => a.auditId === result.model.auditId);
    if (index > -1) {
      allLegacyAudits.value[index] = result.model;
    }
    next(() => {
      showForm.value = false;
    });
  }
};

watch(route, (to) => {
  // Reset and hide the forms on route change
  currentAudit.value = null;
  showForm.value = false;
  // If navigating to a specific audit, find and show it
  if (to.query?.audit) {
    const audit = legacyAudits.value.find((a) => a.auditId === to.query.audit);
    if (audit) {
      setAudit(audit);
    }
  }
});
// END LEGACY DESKTOP AUDIT

// DESKTOP AUDIT
const audit = useAuditStore();

const activeForm = ref(null);
const selectForm = (form) => {
  activeForm.value = form;
};

const assignAuditor = async ({ updates, next }) => {
  const payload = {
    auditor: audit.desktop.auditor,
    bookingNotes: audit.desktop.bookingNotes,
    noAccess: audit.desktop.noAccess,
    noAccessReasons: audit.desktop.noAccessReasons,
    priority: audit.desktop.priority || 'Normal',
    reasonsForAudit: audit.desktop.reasonsForAudit,
  };
  const result = await api.assignDesktopAuditor(props.project.retrofitProjectId, {
    ...payload,
    ...updates,
  });
  if (result.isSuccess) {
    audit.desktop = result.model;
  }
  if (next && typeof next === 'function') {
    next();
  }
};

const addFormToAudit = async ({ form, next }) => {
  const result = await api.addFormToDesktopAudit(
    props.project.retrofitProjectId,
    form.auditQuestionSetId,
    form.measureId
  );
  audit.desktop = result.model;
  if (next && typeof next === 'function') {
    next();
  }
};

const projectsStore = useProjectsStore();
const moveToReview = async ({ form, next }) => {
  const projectId = props.project.retrofitProjectId;
  const result = await api.desktopAuditMoveToReview(projectId, form);
  if (result) {
    audit.desktop = result.model;
    showNotification(result.message);
    const fetchProject =
      audit.desktop.lodgementOfWorkStyle === 'Standalone'
        ? api.getStandaloneLodgementProject
        : api.getProjectFull;
    const project = await fetchProject(projectId);
    if (project) {
      projectsStore.cache(projectId, project).set(projectId);
    }
    if (next && typeof next === 'function') {
      next();
    }
  }
};

const adminMoveToReview = async () => {
  const projectId = props.project.retrofitProjectId;
  const result = await api.desktopAuditAdminMoveToReview(projectId);
  if (result) {
    audit.desktop = result.model;
    const fetchProject =
      audit.desktop.lodgementOfWorkStyle === 'Standalone'
        ? api.getStandaloneLodgementProject
        : api.getProjectFull;
    const project = await fetchProject(projectId);
    if (project) {
      projectsStore.cache(projectId, project).set(projectId);
    }
  }
};

const reviewCompleteAudit = async ({ next }) => {
  const projectId = props.project.retrofitProjectId;
  const result = await api.desktopAuditComplete(projectId);
  if (result) {
    audit.desktop = result.model;
    const fetchProject =
      audit.desktop.lodgementOfWorkStyle === 'Standalone'
        ? api.getStandaloneLodgementProject
        : api.getProjectFull;
    const project = await fetchProject(projectId);
    if (project) {
      projectsStore.cache(projectId, project).set(projectId);
    }
    if (next && typeof next === 'function') {
      next();
    }
  }
};

const clearAudit = async () => {
  const projectId = props.project.retrofitProjectId;

  for (const form of audit.desktop.forms) {
    const auditForm = await api.getAuditForm(projectId, form.auditFormId);
    if (auditForm.isSuccess) {
      for (const question of auditForm.model.questions.filter((x) => x.type === 'File')) {
        const id = JSON.parse(question.answer)?.id;
        if (id) {
          await api.deleteAuditFile(id);
        }
      }
    }
  }

  const result = await api.clearDesktopAudit(projectId);
  if (result) {
    const desktopResult = await api.getDesktopAudit(props.project.retrofitProjectId);
    if (desktopResult) {
      audit.desktop = desktopResult.model;
    }
    const fetchProject =
      audit.desktop.lodgementOfWorkStyle === 'Standalone'
        ? api.getStandaloneLodgementProject
        : api.getProjectFull;
    const project = await fetchProject(projectId);
    if (project) {
      projectsStore.cache(projectId, project).set(projectId);
    }
  }
};

const onFormComplete = () => {
  activeForm.value = null;
  initialise();
};

// END DESKTOP AUDIT

// Initialisation
const loaded = ref(false);
const initialise = async () => {
  const legacyResult = await api.silently().getAudits(props.project.retrofitProjectId);
  if (legacyResult) {
    allLegacyAudits.value = legacyResult.model;
  }
  let result = true;
  result = await api.silently().getDesktopAudit(props.project.retrofitProjectId);
  if (result && result.model) {
    audit.desktop = result.model;
  }
  if (legacyResult && result) {
    loaded.value = true;
  }
};
onBeforeMount(initialise);
</script>
