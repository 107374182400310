<template>
  <DrawerOverlay title="Complete Desktop Audit" @dismiss="emit('dismiss')">
    <form @submit.prevent="onSubmit">
      <div v-if="incompleteForms.length" class="audit-section">
        <FormWrapper label="Incomplete Forms">
          <p class="text-secondary small lh-sm">
            Please provide reasons for forms that have not been completed
          </p>
          <div class="d-flex flex-column gap-3">
            <div
              v-for="{ auditFormId, questionSetTitle, measureDescription } of incompleteForms"
              :key="auditFormId"
            >
              <FormMultiSelect
                :id="`reasons-${auditFormId}`"
                :label="questionSetTitle"
                :sublabel="measureDescription"
                :options="appData.onsiteIncompleteReasonTypes"
                v-model="form.reasons[`${auditFormId}`]"
              />
            </div>
          </div>
        </FormWrapper>
      </div>
      <div class="audit-section d-flex flex-column gap-3">
        <FormTextarea id="notes" label="Internal Notes" v-model="form.internalNotes" />
      </div>
      <div class="audit-section">
        <div>
          <p class="text-secondary small lh-sm">
            On submitting this, the desktop audit will be reviewed internally before finally
            completing.
          </p>
        </div>
        <button class="btn btn-primary w-100" type="submit">Complete and Review</button>
      </div>
    </form>
  </DrawerOverlay>
</template>

<script setup>
import { ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useAppStore } from '../stores/app';
import DrawerOverlay from './DrawerOverlay.vue';
import FormMultiSelect from './FormMultiSelect.vue';
import FormTextarea from './FormTextarea.vue';
import FormWrapper from './FormWrapper.vue';

const { appData } = storeToRefs(useAppStore());

const emit = defineEmits(['confirm', 'dismiss']);
const props = defineProps({
  forms: {
    type: Array,
    default: () => [],
  },
  current: {
    type: Object,
    default: () => {},
  },
});

const incompleteForms = computed(() => props.forms.filter((i) => !i.isComplete));

const form = ref({
  observations: props.current.observations || '',
  internalNotes: props.current.internalNotes || '',
  reasons: {},
});

const onSubmit = () => {
  emit('confirm', form.value);
};
</script>
