<template>
  <Component :is="props.is" ref="el">
    <slot />
  </Component>
</template>

<script setup>
import { Popover as bootstrapPopover } from 'bootstrap';
import { onMounted, onBeforeUnmount, ref, watch } from 'vue';

const props = defineProps({
  is: {
    type: String,
    default: 'div',
  },
  container: {
    type: String,
    default: 'body',
  },
  trigger: {
    type: String,
    default: 'click',
  },
  placement: {
    type: String,
    default: 'top',
  },
  content: {
    type: String,
    default: 'Content',
  },
  offset: {
    type: [Array, String],
    default: () => [0, 2],
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const el = ref(null);
let popover = null;

onMounted(() => {
  if (el.value) {
    popover = new bootstrapPopover(el.value, {
      container: props.container,
      trigger: props.trigger,
      placement: props.placement,
      content: props.content,
      offset: props.offset,
      html: true,
    });
  }
  if (props.disabled) {
    popover.disable();
  }
});

watch(
  () => props.disabled,
  (disabled) => {
    if (disabled) popover.disable();
    else popover.enable();
  }
);

onBeforeUnmount(() => popover?.dispose());
</script>
