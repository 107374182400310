import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

export const sentryPlugin = {
  install(app, { router } = {}) {
    const { VITE_ENV, VITE_BUILD_VERSION, VITE_SENTRY_DSN, VITE_SENTRY_DEBUG, VITE_SENTRY_ORIGIN } =
      import.meta.env;
    if (VITE_SENTRY_DSN && router) {
      Sentry.init({
        app,
        dsn: VITE_SENTRY_DSN,
        integrations: [
          new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ['localhost', /^\//, VITE_SENTRY_ORIGIN],
          }),
        ],
        environment: VITE_ENV,
        tracesSampleRate: 1.0,
        debug: VITE_SENTRY_DEBUG === 'true' || VITE_SENTRY_DEBUG === true,
        release: VITE_BUILD_VERSION,
      });
    }
  },
};
