<template>
  <nav :aria-label="ariaLabel" v-if="totalPages > 1" class="mt-4">
    <ul class="pagination">
      <li class="page-item" :class="{ disabled: page <= 1 }">
        <a
          class="page-link bi-chevron-left px-2"
          href="#"
          aria-label="Previous"
          @click.prevent="onPageChange(page - 1)"
        />
      </li>
      <li class="page-item" :class="{ active: page === p }" v-for="p in pagesToShow" :key="p">
        <a class="page-link" href="#" @click.prevent="onPageChange(p)">{{ p }}</a>
      </li>
      <li class="page-item" :class="{ disabled: page == totalPages }">
        <a
          class="page-link bi-chevron-right px-2"
          href="#"
          aria-label="Next"
          @click.prevent="onPageChange(page + 1)"
        />
      </li>
    </ul>
  </nav>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  page: {
    type: Number,
    required: true,
  },
  totalPages: {
    type: Number,
    required: true,
  },
  pageSize: {
    type: Number,
    required: true,
  },
  totalItems: {
    type: Number,
    required: true,
  },
  ariaLabel: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['change']);

const onPageChange = (page) => {
  emit('change', page);
};

// Probably a better way to do this
const pagesToShow = computed(() => {
  const { page } = props;
  const arr = [page];

  if (page > 1) {
    arr.unshift(page - 1);
  }
  if (page > 2) {
    arr.unshift(page - 2);
  }
  if (page < props.totalPages) {
    arr.push(page + 1);
  }
  if (page + 1 < props.totalPages) {
    arr.push(page + 2);
  }
  if (arr.length < 5 && page + 2 < props.totalPages) {
    arr.push(page + 3);
  }
  if (arr.length < 5 && page + 3 < props.totalPages) {
    arr.push(page + 4);
  }
  return arr;
});
</script>
