<template>
  <div class="form-group" :class="{ required: isRequired, disabled, active: isDragActive }">
    <label v-if="label" class="form-label">{{ label }}</label>
    <FormPopover
      v-if="(!disabled && description) || (description && popoverOverride)"
      :content="description"
    />
    <div v-if="props.showFiles" v-for="(file, i) in files" :key="file.id" class="input-group" :class="{ 'mt-1': i > 0 }">
      <div class="form-control overflow-hidden">{{ file.title }}</div>
      <button
        v-if="file.href && props.showDownload"
        aria-label="Download file"
        class="btn btn-outline-neutral text-primary"
        type="button"
        @click.prevent="onDownload(file.href)"
      >
        <i class="bi bi-download" />
      </button>
      <button
        v-if="file.href && props.showView"
        class="btn btn-outline-neutral text-primary"
        type="button"
        @click.prevent="onView(file)"
      >
        <i class="bi bi-eye" />
      </button>
      <button
        v-if="!props.disabled"
        aria-label="Remove file"
        class="btn btn-outline-neutral text-danger"
        type="button"
        @click.prevent="onRemove(file)"
      >
        <i class="bi bi-x-lg" />
      </button>
    </div>
    <div
      v-if="(!disabled && files.length < maxFiles) || (disabled && files.length === 0)"
      v-bind="getRootProps()"
      class="file-drop"
      :class="{ 'mt-2': files.length }"
    >
      <input v-bind="getInputProps()" :disabled="disabled" />
      {{ dropzoneText }}
    </div>
    <div v-if="error" class="invalid-feedback d-block">{{ error }}</div>
  </div>
</template>

<script setup>
import { useDropzone } from 'vue3-dropzone';
import { showNotification } from '../utilities/notification';
import FormPopover from './FormPopover.vue';
import { useApi } from '../stores/api';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { getLocation } from '../utilities/geolocation';
import { getPlatform } from '../utilities/platform';

const api = useApi();
const route = useRoute();

const emit = defineEmits(['complete', 'remove', 'download', 'view']);
const props = defineProps({
  label: {
    type: String,
    default: 'Document Upload',
  },
  files: {
    type: [Object, Array],
    default: undefined,
  },
  endpoint: {
    type: String,
    default: 'file',
  },
  showFiles: {
    type: Boolean,
    default: true,
  },
  maxFiles: {
    type: Number,
    default: 1,
  },
  acceptTypes: {
    type: Array,
    default: () => [
      'image/png',
      'image/jpeg',
      'image/gif',
      'application/pdf',
      'application/vnd.ms-word',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-outlook',
      'video/mp4',
    ],
  },
  validator: {
    type: Object,
    default: null,
  },
  description: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
  auditAddress: {
    type: String,
    default: '',
  },
  popoverOverride: {
    type: Boolean,
    default: false,
  },
  showView: {
    type: Boolean,
    default: false,
  },
  showDownload: {
    type: Boolean,
    default: true,
  },
});

const busy = ref(false);

const onDrop = async (acceptFiles, rejectReasons) => {
  try {
    if (rejectReasons.length > 0) {
      for (const r of rejectReasons) {
        for (const e of r.errors) {
          showNotification(e.message, true);
        }
      }
    }

    if (acceptFiles.length > 0) {
      busy.value = true;
      for (const file of acceptFiles) {
        try {
          let result;
          let config;
          if (props.endpoint === 'file') {
            result = await api.throwOnError().getSignedUrl(projectId, file.name);
          } else if (props.endpoint === 'audit') {
            const location = await getLocation();
            const platform = getPlatform();
            result = await api
              .throwOnError()
              .getAuditSignedUrl(
                projectId,
                file.name,
                location.timestamp,
                location.coords.latitude,
                location.coords.longitude,
                platform,
                props.auditAddress
              );
            config = {
              headers: {
                'x-amz-meta-timestamp': location.timestamp,
                'x-amz-meta-latitude': location.coords.latitude,
                'x-amz-meta-longitude': location.coords.longitude,
                'x-amz-meta-platform': platform,
                'x-amz-meta-address': encodeURIComponent(props.auditAddress).replace(/'/g, '%27'),
              },
            };
          }
          await api.throwOnError().uploadFileToS3(result, file, config);

          emit('complete', {
            file,
            link: result.split('?')[0],
          });
        } catch (e) {
          // console.log(e);
          api.error('Could not upload file, please try again.');
        }
      }
    }
  } finally {
    busy.value = false;
  }
};

const onDownload = (url) => {
  emit('download', { link: url, projectOrAssessmentId: projectId });
};

const onRemove = (file) => {
  emit('remove', file);
};

const { getRootProps, getInputProps, isDragActive } = useDropzone({
  onDrop,
  accept: props.acceptTypes,
  maxFiles: props.maxFiles,
  disabled: props.disabled,
});

const projectId = route.params.projectId || route.params.assessmentId;

const isRequired = computed(() => props.required || props.validator?.required);

const dropzoneText = computed(() => {
  if (props.disabled) return 'No file provided';
  if (busy.value) return 'Uploading...';
  if (isDragActive.value) return 'Drop the file here...';
  return 'Drag and drop a file here, or click to select a file';
});

const error = computed(() => {
  return props.validator?.$errors?.[0]?.$message;
});

const files = computed(() => {
  if (!props.files) return [];
  if (Array.isArray(props.files)) {
    return props.files.filter((x) => x.id);
  }
  return [props.files].filter((x) => x.id);
});

const onView = async (file) => {
  emit('view', file);
};
</script>

<style lang="scss" scoped>
.file-drop {
  border: 1px dashed $border-color;
  border-radius: $border-radius;
  padding: 1.25rem $input-padding-x;
  text-align: center;
  cursor: pointer;
  transition: 200ms ease color, 200ms ease border-color;
  &:hover {
    border-color: $secondary;
  }
}
.active .file-drop {
  color: $primary;
  border-color: $primary;
}
.disabled {
  .form-control {
    background-color: $input-disabled-bg;
  }
  .file-drop {
    cursor: default;
    border-style: solid;
    background: $input-disabled-bg;
    padding: $input-padding-y $input-padding-x;
    &:hover {
      border-color: $border-color;
    }
  }
}
</style>
