<template>
  <div>
    <div v-for="(option, i) of props.options" :key="option.id" class="mb-2">
      <input
        class="btn-check"
        type="radio"
        :name="props.id"
        :id="`${props.id}-${i}`"
        :value="option"
        v-model="value"
      />
      <label class="btn" :for="`${props.id}-${i}`"
        >{{ option }} <i class="bi-check-circle-fill"
      /></label>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  modelValue: {
    type: [String, Number],
    default: '',
  },
  options: {
    type: Array,
    default: () => [],
  },
});

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});
</script>

<style lang="scss" scoped>
.btn {
  border-radius: $border-radius;
  border: 1px solid $gray-300;
  color: $gray-600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  text-align: left;
  width: 100%;
  > i {
    font-size: 1.25em;
    visibility: hidden;
  }
}
.btn-check:checked + .btn {
  background-color: inherit;
  color: $primary;
  border-color: $primary;
  > i {
    visibility: visible;
  }
}
</style>
