<template>
  <DrawerOverlay title="Add a Form" @dismiss="emit('dismiss')">
    <form @submit.prevent="onSubmit">
      <div class="audit-section">
        <FormSelect
          class="mt-3"
          id="form"
          label="Form"
          type="form"
          :validator="v$.auditQuestionSetId"
          v-model="form.auditQuestionSetId"
        >
          <option
            v-for="q in questionSets"
            :key="q.auditQuestionSetId"
            :value="q.auditQuestionSetId"
          >
            {{ q.formName }}
          </option>
        </FormSelect>
        <FormSelect
          class="mt-3"
          id="measure"
          label="Measure"
          type="measure"
          :validator="v$.measureId"
          v-model="form.measureId"
        >
          <option v-for="m in measures" :key="m.measureId" :value="m.measureId">
            {{ m.measureCategory }} / {{ m.measureType }}
          </option>
        </FormSelect>
        <div class="mt-3 d-flex flex-column gap-2">
          <button class="btn btn-primary" type="submit">Add Form</button>
        </div>
      </div>
    </form>
  </DrawerOverlay>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import DrawerOverlay from './DrawerOverlay.vue';
import FormSelect from './FormSelect.vue';
import { helpers, required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { useApi } from '../stores/api';
import { useProjectsStore } from '../stores/projects';
import { storeToRefs } from 'pinia';

const api = useApi();
const projectsStore = useProjectsStore();
const { project } = storeToRefs(projectsStore);

const emit = defineEmits(['confirm', 'dismiss']);
const props = defineProps({
  projectId: {
    type: String,
    required: true,
  },
  bespokeAuditId: {
    type: String,
    required: true,
  },
});

const rules = {
  auditQuestionSetId: {
    required: helpers.withMessage('Form is required', required),
  },
  measureId: {
    required: helpers.withMessage('Measure is required', required),
  },
};

const form = ref({
  auditQuestionSetId: '',
  measureId: '',
});

const measures = computed(() => {
  return (
    project.value?.lodgements?.filter((x) => x.status === 'Complete')?.flatMap((x) => x.measures) ??
    []
  );
});

const questionSets = ref([]);

const v$ = useVuelidate(rules, form, { $stopPropagation: true });

const onSubmit = async () => {
  const valid = await v$.value.$validate();
  if (valid) {
    emit('confirm', form.value);
  }
};

onMounted(async () => {
  const result = await api.getBespokeAuditManualQuestionSets(props.bespokeAuditId, props.projectId);
  if (result.model) {
    questionSets.value = result.model;
  }
});
</script>
