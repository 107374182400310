import { defineStore } from 'pinia';

export const idKey = 'responseId';

export const useResponsesStore = defineStore({
  id: 'responses',
  state: () => ({
    response: null,
    responses: null,
    responsesCache: {},
    searchParams: {
      term: '',
      pageSize: 12,
      page: 0,
      totalPages: 0,
      totalItems: 0,
    },
  }),
  // Individual
  actions: {
    get(id) {
      return this.responsesCache[id];
    },
    cache(id, response) {
      this.responsesCache[id] = response;
      return this;
    },
    set(id) {
      const cached = this.responsesCache[id];
      if (!cached) throw new Error(`Response with ID “${id}” not found`);
      this.response = cached;
      return this;
    },
    unset() {
      this.response = null;
      return this;
    },
    unlist(id) {
      this.responses = this.responses?.filter((response) => response[idKey] !== id) || null;
      return this;
    },
    uncache(id) {
      delete this.responsesCache[id];
      return this;
    },
    remove(id) {
      this.uncache(id);
      this.unlist(id);
      return this;
    },
    // List
    setList(responses) {
      this.responses = responses;
      return this;
    },
  },
});
