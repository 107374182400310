<template>
  <div class="form-group" :class="{ required }">
    <div v-if="label">
      <label class="form-label">{{ label }}</label>
    </div>
    <slot />
    <div v-if="error" class="invalid-feedback d-block">
      {{ error }}
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
  validator: {
    type: Object,
    default: null,
  },
});

const error = computed(() => props.validator?.$errors?.[0]?.$message);

const required = computed(() => {
  return props.required || props.validator?.required?.$invalid;
});
</script>
