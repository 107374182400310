<template>
  <div class="form-group" :class="{ required: isRequired }">
    <div class="d-flex align-items-top">
      <label v-if="props.label" :for="props.id" class="form-label">{{ props.label }}</label>
      <FormPopover v-if="props.popover" :content="props.popover" />
    </div>
    <div class="input-group" :class="{ 'has-validation': !!error }">
      <slot name="prepend" />
      <select
        :id="id"
        class="form-select"
        :class="classOverride || { 'is-invalid': error }"
        v-model="value"
        :disabled="props.disabled"
      >
        <slot>
          <option
            v-for="option of options"
            :key="option.value || option"
            :value="option.value || option"
          >
            {{ option.title || option }}
          </option>
        </slot>
      </select>
      <slot name="append" />
      <div v-if="error" class="invalid-feedback">{{ error }}</div>
    </div>
    <div v-if="description" class="mt-1 text-secondary">
      <small>{{ description }}</small>
    </div>
  </div>
</template>

<script setup>
import { inputProps, inputEmits, useFormInput } from '../utilities/form';
import FormPopover from './FormPopover.vue';
const emit = defineEmits(inputEmits);
const props = defineProps({
  ...inputProps,
  options: {
    type: Array,
    default: () => [],
  },
  classOverride: {
    type: String,
    default: null,
  },
});

const { value, isRequired, error } = useFormInput(props, emit);
</script>
