<template>
  <div class="form-group" :class="{ required: isRequired }">
    <div class="d-flex align-items-top">
      <label v-if="props.label" :for="props.id" class="form-label">{{ props.label }}</label>
      <FormPopover v-if="props.popover" :content="props.popover" />
    </div>
    <div class="input-group" :class="{ 'has-validation': !!error }">
      <slot name="prepend" />
      <textarea
        :id="id"
        class="form-control"
        :class="{ 'is-invalid': error }"
        :disabled="disabled"
        :rows="rows"
        :placeholder="placeholder"
        :maxlength="maxLength"
        v-model="value"
      />
      <slot name="append" />
      <div v-if="error" class="invalid-feedback">{{ error }}</div>
    </div>
    <div v-if="maxLength && maxLength < 524288" class="mt-1 text-secondary d-flex flex-row-reverse">
      {{ value?.length }} / {{ maxLength }}
    </div>
    <div v-if="description" class="mt-1 text-secondary">
      <small>{{ description }}</small>
    </div>
  </div>
</template>

<script setup>
import { inputProps, inputEmits, useFormInput } from '../utilities/form';
import FormPopover from './FormPopover.vue';
const emit = defineEmits(inputEmits);
const props = defineProps({
  ...inputProps,
  rows: {
    type: [String, Number],
    default: 4,
  },
  placeholder: {
    type: String,
    default: '',
  },
  maxLength: {
    type: Number,
    default: 524288,
  },
});

const { value, isRequired, error } = useFormInput(props, emit);
</script>
