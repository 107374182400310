<template>
  <div v-if="loaded" class="drawer-content">
    <KeepAlive include="AuditOnsiteOverview">
      <AuditOnsiteAuditor
        v-if="!audit.onsite.auditor"
        :project="props.project"
        @update="updateAudit"
      />
      <AuditOnsiteQuestionnaire
        v-else-if="activeForm"
        :project="project"
        :form="activeForm"
        :audit="audit.onsite"
        @dismiss="activeForm = null"
        @complete="onFormComplete"
        @restart="onFormRestart"
      />
      <AuditOnsiteOverview
        v-else
        :project="props.project"
        :lodgement="props.lodgement"
        @form="selectForm"
        @update="updateAudit"
        @add-form="addFormToAudit"
        @complete="moveToReview"
        @review-complete="reviewCompleteAudit"
        @admin-move-to-review="adminMoveToReview"
        @clear-audit="clearAudit"
      />
    </KeepAlive>
  </div>
  <div v-else class="drawer-content p-5 text-center">
    <span class="spinner-border" />
  </div>
</template>

<script setup>
import { onBeforeMount, ref } from 'vue';
import { useApi } from '../stores/api';
import { useAuditStore } from '../stores/audit';
import { useProjectsStore } from '../stores/projects';
import AuditOnsiteAuditor from './AuditOnsiteAuditor.vue';
import AuditOnsiteOverview from './AuditOnsiteOverview.vue';
import AuditOnsiteQuestionnaire from './AuditOnsiteQuestionnaire.vue';
import { showNotification } from '../utilities/notification';

const activeForm = ref(null);
const selectForm = (form) => {
  activeForm.value = form;
};

const props = defineProps({
  project: {
    type: Object,
    required: true,
  },
  lodgement: {
    type: Object,
    default: null,
  },
});

const api = useApi();
const audit = useAuditStore();

const updateAudit = async ({ updates, next }) => {
  const payload = {
    auditor: audit.onsite.auditor,
    bookingNotes: audit.onsite.bookingNotes,
    noAccess: audit.onsite.noAccess,
    noAccessReasons: audit.onsite.noAccessReasons,
    priority: audit.onsite.priority || 'Normal',
    reasonsForAudit: audit.onsite.reasonsForAudit,
  };
  const result = await api.updateOnsiteAuditBooking(props.project.retrofitProjectId, {
    ...payload,
    ...updates,
  });
  if (result.isSuccess) {
    audit.onsite = result.model;
  }
  if (next && typeof next === 'function') {
    next();
  }
};

const addFormToAudit = async ({ form, next }) => {
  const result = await api.addFormToOnsiteAudit(
    props.project.retrofitProjectId,
    form.auditQuestionSetId,
    form.measureId
  );
  audit.onsite = result.model;
  if (next && typeof next === 'function') {
    next();
  }
};

const projectsStore = useProjectsStore();
const moveToReview = async ({ form, next }) => {
  const projectId = props.project.retrofitProjectId;
  const result = await api.moveToReview(projectId, form);
  if (result) {
    audit.onsite = result.model;
    const fetchProject =
      audit.onsite.lodgementOfWorkStyle === 'Standalone'
        ? api.getStandaloneLodgementProject
        : api.getProjectFull;
    const project = await fetchProject(projectId);
    if (project) {
      projectsStore.cache(projectId, project).set(projectId);
    }
    if (next && typeof next === 'function') {
      next();
    }
  }
};

const clearAudit = async () => {
  const projectId = props.project.retrofitProjectId;

  for (const form of audit.onsite.forms) {
    const auditForm = await api.getAuditForm(projectId, form.auditFormId);
    if (auditForm.isSuccess) {
      for (const question of auditForm.model.questions.filter((x) => x.type === 'File')) {
        const id = JSON.parse(question.answer)?.id;
        if (id) {
          await api.deleteAuditFile(id);
        }
      }
    }
  }

  const result = await api.clearAudit(projectId);
  if (result) {
    const onsiteResult = await api.getOnsiteAudit(props.project.retrofitProjectId);
    if (onsiteResult) {
      audit.onsite = onsiteResult.model;
    }
    const fetchProject =
      audit.onsite.lodgementOfWorkStyle === 'Standalone'
        ? api.getStandaloneLodgementProject
        : api.getProjectFull;
    const project = await fetchProject(projectId);
    if (project) {
      projectsStore.cache(projectId, project).set(projectId);
    }
  }
};

const adminMoveToReview = async () => {
  const projectId = props.project.retrofitProjectId;
  const result = await api.adminMoveToReview(projectId);
  showNotification(result.message);
  if (result) {
    audit.onsite = result.model;
    const fetchProject =
      audit.onsite.lodgementOfWorkStyle === 'Standalone'
        ? api.getStandaloneLodgementProject
        : api.getProjectFull;
    const project = await fetchProject(projectId);
    if (project) {
      projectsStore.cache(projectId, project).set(projectId);
    }
  }
};

const reviewCompleteAudit = async ({ next }) => {
  const projectId = props.project.retrofitProjectId;
  const result = await api.reviewCompleteOnsiteAudit(projectId);
  if (result) {
    audit.onsite = result.model;
    const fetchProject =
      audit.onsite.lodgementOfWorkStyle === 'Standalone'
        ? api.getStandaloneLodgementProject
        : api.getProjectFull;
    const project = await fetchProject(projectId);
    if (project) {
      projectsStore.cache(projectId, project).set(projectId);
    }
    if (next && typeof next === 'function') {
      next();
    }
  }
};

// Initialisation
const loaded = ref(false);
const initialise = async () => {
  const result = await api.silently().getOnsiteAudit(props.project.retrofitProjectId);
  if (result) {
    audit.onsite = result.model;
    loaded.value = true;
  }
};
onBeforeMount(initialise);

const onFormComplete = () => {
  activeForm.value = null;
  initialise();
};

const onFormRestart = async () => {
  const result = await api.restartOnsiteAuditForm(
    audit.onsite.retrofitProjectId,
    activeForm.value.auditFormId
  );
  if (result && result.model) {
    activeForm.value = null;
    audit.onsite = result.model;
  }
};
</script>
