<template>
  <FormUpload
    endpoint="audit"
    :audit-address="auditAddress"
    :id="'upload' + id"
    :files="files"
    :accept-types="['image/jpg', 'image/jpeg', 'image/png', 'image/gif']"
    @complete="uploadFile"
    @remove="removeFile"
    @download="downloadFile"
  />
  <QuestionSelect
    class="mt-4"
    v-if="props.captionOptions?.length > 0"
    :options="props.captionOptions"
    v-model="captionAlt"
    @update:model-value="updateCaption"
  />
  <FormTextarea
    class="mt-4"
    :id="'input' + id"
    label="Caption"
    v-model="caption"
    placeholder=""
    v-if="!isDisabled"
    @update:model-value="updateCaption"
  />
</template>

<script setup>
import FormUpload from './FormUpload.vue';
import FormTextarea from './FormTextarea.vue';
import { computed, ref } from 'vue';
import QuestionSelect from './QuestionSelect.vue';

const emit = defineEmits(['complete', 'remove', 'download']);

const props = defineProps({
  files: {
    type: [Object, Array],
    default: undefined,
  },
  endpoint: {
    type: String,
    default: 'file',
  },
  auditAddress: {
    type: String,
    default: '',
  },
  captionOptions: {
    type: Array,
    default: () => [],
  },
  id: {
    type: String,
    default: '',
  },
});

const value = computed({
  get() {
    return props.files;
  },
  set(value) {
    emit('complete', value);
  },
});

const caption = ref(props.files?.caption);
const captionAlt = ref(
  props.files?.caption?.length > 0 &&
    props.captionOptions?.length > 0 &&
    props.captionOptions.indexOf(props.files?.caption) === -1 &&
    props.captionOptions.find((x) => x.toLowerCase() === 'other')
    ? props.captionOptions.find((x) => x.toLowerCase() === 'other')
    : props.files?.caption
);

const uploadFile = (result) => {
  value.value = {
    ...result,
    caption: caption.value,
  };
};

const updateCaption = (v) => {
  if (props.captionOptions?.length > 0 && captionAlt.value?.toLowerCase() === 'other') {
    value.value = {
      ...value.value,
      caption: caption.value,
    };
  } else {
    value.value = {
      ...value.value,
      caption: v,
    };
  }
};

const isDisabled = computed(() => {
  if (props.captionOptions?.length > 0 && captionAlt.value?.toLowerCase() !== 'other') {
    return true;
  }
  return false;
});

const removeFile = () => {
  emit('remove');
};

const downloadFile = (url) => {
  emit('download', url);
};
</script>
