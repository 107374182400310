<template>
  <LayoutAuth title="Two-Factor Authentication" :form="props.form">
    <template #header>
      <p class="text-secondary lh-sm mt-2">
        <small>We would like to verify you.</small>
      </p>
    </template>
    <template #default="{ submitStage, submitButton }">
      <form v-if="!totpEnabled" @submit.prevent="submitStage(handleSubmit)">
          <div class="mt-4 d-flex justify-content-between">
              <Component :is="submitButton">Send SMS Verification Code</Component>
          </div>
      </form>
      <form v-else @submit.prevent="submitStage(handleSubmit)">
          <div class="mt-4">
              <FormInput
                  v-model="totpCode"
                  class="w-full"
                  description="Please enter the code displayed on your authenticator app"
              />
          </div>
          <Component :is="submitButton" class="mt-4">Authenticate</Component>
      </form>
    </template>
    <template #footer>
      <RouterLink v-if="!totpEnabled" :to="{ name: 'login' }" class="text-body" @click.prevent="onChangeMobile"
        >Change Mobile Number</RouterLink
      >
      <span v-if="!totpEnabled" class="mx-2">•</span>
      <RouterLink :to="{ name: 'login' }" class="text-body" @click.prevent="onReset"
        >Back to Login</RouterLink
      >
    </template>
  </LayoutAuth>
</template>

<script setup>
import LayoutAuth from './LayoutAuth.vue';
import { useAuthStore } from '../stores/auth';
import { computed, ref } from 'vue'
import FormInput from './FormInput.vue'
import { formProp } from '../utilities/auth'
import { useRouter } from 'vue-router'

const props = defineProps(formProp)
const auth = useAuthStore()
const router = useRouter()
const totpEnabled = computed(() => auth.usesTotp)
const totpCode = ref('')

const handleSubmit = async (form) => {
  if (totpEnabled.value) {
      await auth.verifyTotpCode(totpCode.value)
      await auth.login({
          username: form.email,
          password: form.password,
      })
      router.push({ name: 'dashboard' })
  } else {
      await auth.sendSmsCode()
  }
};

const onChangeMobile = () => {
  auth.mobileNumber = null;
};

const onReset = () => {
  auth.$reset();
};
</script>
