<template>
  <div>
    <h6 class="mb-2 text-secondary">{{ props.title }}</h6>
    <div class="recent-thumbs fs-6">
      <RouterLink
        v-for="item of validItems"
        :key="item.id"
        class="recent-thumb"
        :class="item.status?.toLowerCase()"
        :to="item.to"
      >
        <div class="card-body px-3 py-2 d-flex">
          <strong>{{ item.title }}</strong>
        </div>
      </RouterLink>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  title: {
    type: String,
    default: 'Recently Viewed',
  },
});

const validItems = computed(() => props.items.filter((item) => !!item.id));
</script>

<style lang="scss" scoped>
.recent-thumbs {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  margin-bottom: -0.5rem;
}
.recent-thumb {
  text-decoration: none;
  background-color: $light;
  color: $secondary;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  border-right-color: $secondary;
  border-right-width: 5px;
  margin: 0 0.5rem 0.5rem 0;
  transition: 200ms ease box-shadow, 200ms ease border-color;
  &:hover {
    box-shadow: $box-shadow-sm;
  }
  &:last-child {
    margin-right: 0;
  }
  &.draft {
    border-right-color: $warning;
  }
  &.new {
    border-right-color: $warning;
  }
  &.inprogress {
    border-right-color: $primary;
  }
  &.complete {
    border-right-color: $success;
  }
  &.superseded {
    border-right-color: $primary;
  }
}
</style>
