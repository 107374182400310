<template>
  <DrawerOverlay title="Escalate to Assurance" @dismiss="emit('dismiss')">
    <form @submit.prevent="onSubmit">
      <div class="audit-section">
        <FormDate
          id="auditDate"
          label="Audit Date"
          v-model="form.auditDate"
        />
        <FormTextarea
          id="comment"
          label="Comment"
          class="mt-4"
          v-model="form.comment"
        />
        <button class="mt-3 btn btn-danger w-100" type="submit">Confirm Escalation</button>
      </div>
    </form>
  </DrawerOverlay>
</template>

<script setup>
import FormTextarea from './FormTextarea.vue';
import FormDate from './FormDate.vue';
import DrawerOverlay from './DrawerOverlay.vue';
import { ref, onMounted } from 'vue';
const emit = defineEmits(['confirm', 'dismiss']);

const props = defineProps({
  audit: {
    type: Object,
    required: true,
  },
});

const form = ref({ ...props.audit });

const onSubmit = async () => {
  emit('confirm', { comment: form.value.comment, auditDate: form.value.auditDate });
};

onMounted(() => {
  form.value.auditDate = new Date().toISOString();
});
</script>
