<template>
  <div
    class="d-flex flex-column min-vh-100"
    :class="{
      'sidebar-minimized': appState.sidebarMinimized || appState.drawerExpanded,
      'drawer-active': appState.drawerExpanded,
    }"
    :key="key"
  >
    <transition name="fade">
      <div v-if="showLoader" class="loader fixed-top bg-black bg-opacity-25">
        <div class="spinner-border"></div>
      </div>
    </transition>

    <AppNotifications />

    <!-- If Error -->
    <LayoutError v-if="appState.error" :error="appState.error" />
    <!-- If Logged In -->
    <LayoutBase v-else-if="auth.loggedIn && appState.appData" />
    <!-- If Logged Out -->
    <RouterView v-else />
  </div>
</template>

<script setup>
import { computed, onErrorCaptured } from 'vue';
import { RouterView } from 'vue-router';
import { useDebounce } from '@vueuse/core';
import { useApi } from './stores/api';
import { useAuthStore } from './stores/auth';
import { useAppStore } from './stores/app';
import { useAccountStore } from './stores/account';
import AppNotifications from './components/AppNotifications.vue';
import LayoutBase from './components/LayoutBase.vue';
import LayoutError from './components/LayoutError.vue';

const appState = useAppStore();
const auth = useAuthStore();
const api = useApi();
const account = useAccountStore();
// Force an entire app refresh when the account changes
const key = computed(() => account.account?.retrofitAccountId || 'singleaccount');

const isBusy = computed(() => api.count && !api.silent);
// Debounce to prevent flash between requests
const showLoader = useDebounce(isBusy, 50);

onErrorCaptured((error) => {
  if (error.status) {
    appState.error = error;
    return false;
  }
});
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap');

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $zindex-loader;
}

.form-group.required .form-label:after {
  content: '*';
  margin-left: 0.125em;
}

.form-description {
  font-size: 0.875rem;
  color: $secondary;
  width: 100%;
  margin-top: 0.5rem;
}

.form-label + .form-description {
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
}

.form-section {
  &:not(:first-of-type) {
    padding-top: 2rem;
  }
  &:not(:last-of-type) {
    padding-bottom: 2rem;
    border-bottom: 1px solid $border-color;
  }
  .table {
    margin-bottom: 0;
    tfoot td {
      border-bottom-width: 0;
    }
  }
}

.content-section {
  &:not(:first-of-type) {
    padding-top: 2rem;
  }
  &:not(:last-of-type) {
    padding-bottom: 2rem;
    border-bottom: 1px solid $border-color;
  }
}

// Utilities
// To prevent flex overflow
.mw-0 {
  min-width: 0;
}
.mh-100 {
  min-height: 100%;
}

.fs-xxs {
  font-size: $font-size-xxs;
}

// Transitions
.fade-enter-active,
.fade-leave-active {
  transition: opacity 200ms ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.swipe-right-enter-active,
.swipe-right-leave-active {
  transition: transform 200ms ease, opacity 200ms ease;
}

.swipe-right-enter-from,
.swipe-right-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
</style>
