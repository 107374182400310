<template>
  <DrawerOverlay title="Audit" @dismiss="emit('dismiss')">
    <form class="p-3" @submit.prevent="onSubmit">
      <fieldset class="d-flex flex-column gap-3" :disabled="audit?.isComplete">
        <FormInput
          id="auditingWhat"
          label="Auditing"
          disabled
          :model-value="sections.find((s) => s.name === type)?.title"
        />
        <FormSelect
          v-if="documents"
          id="whatSupportingDocumentId"
          label="Document"
          v-model="form.whatSupportingDocumentId"
          :validator="v$.whatSupportingDocumentId"
          :options="documents"
          :disabled="!!(audit?.whatSupportingDocumentId && audit?.auditId)"
        >
          <optgroup v-if="documentsUnaudited?.length" label="Unaudited">
            <option v-for="a of documentsUnaudited" :key="a.value" v-bind="a" />
          </optgroup>
          <optgroup v-if="documentsAudited?.length" label="Audited">
            <option v-for="a of documentsAudited" :key="a.value" v-bind="a" />
          </optgroup>
          <template #append>
            <div class="input-group-text bg-white text-primary" v-if="documentUrl === true">
              <i class="spinner-border spinner-border-sm" />
            </div>
            <a
              v-else-if="documentUrl"
              :href="documentUrl"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Download file"
              class="btn btn-outline-neutral text-primary"
              ><i class="bi bi-download" /></a
          ></template>
        </FormSelect>
        <FormDate id="date" label="Date" :validator="v$.auditDate" v-model="form.auditDate" />
        <FormSelect
          id="type"
          label="Type"
          :options="appData.auditTypes"
          :validator="v$.type"
          v-model="form.type"
        />
        <FormSelect
          id="reason"
          label="Reason"
          :options="appData.auditReasons"
          :validator="v$.reason"
          v-model="form.reason"
        />
        <FormTextarea id="comment" label="Comment" v-model="form.comment" />
        <FormSelect
          id="outcome"
          label="Outcome"
          :options="appData.auditMeasureOutcomes"
          :validator="v$.outcome"
          description="A remediation will be raised for 'Desktop - Non Compliant' outcomes when auditing Lodgement Measures"
          v-model="form.outcome"
        />
        <FormMultiSelect
          v-if="form.outcome && form.outcome !== 'Pass'"
          id="failureReasons"
          :label="failureReasonsLabel"
          :options="auditFailures"
          :validator="v$.failureReasons"
          v-model="form.failureReasons"
          :disabled="audit?.isComplete"
        />

        <FormCheckbox
          v-if="!audit?.isComplete"
          id="isComplete"
          label="Complete Audit"
          description="Please ensure all information is correct before flagging an audit as complete."
          v-model="form.isComplete"
        />
        <FormInput
          v-if="form.createdBy"
          id="createdBy"
          label="Created By"
          disabled
          :model-value="form.createdBy"
        />
        <FormInput
          v-if="form.createdAt"
          id="createdAt"
          label="Created At"
          disabled
          :model-value="formatISOStringDate(form.createdAt, 'EEE do MMM yyyy \'at\' hh:mmaaa')"
        />
        <FormInput
          v-if="form.updatedBy"
          id="updatedBy"
          label="Updated By"
          disabled
          :model-value="form.updatedBy"
        />
        <FormInput
          v-if="form.updatedAt"
          id="updatedAt"
          label="Updated At"
          disabled
          :model-value="formatISOStringDate(form.updatedAt, 'EEE do MMM yyyy \'at\' hh:mmaaa')"
        />
      </fieldset>

      <fieldset
        v-if="isResolved && (form.resolvedBy || form.resolvedDt)"
        class="mt-3 d-flex flex-column gap-3"
      >
        <FormInput
          v-if="form.resolvedBy"
          id="resolvedBy"
          label="Resolved By"
          disabled
          :model-value="form.resolvedBy"
        />
        <FormInput
          v-if="form.resolvedDt"
          id="resolvedDt"
          label="Resolved At"
          :model-value="formatISOStringDate(form.resolvedDt, 'EEE do MMM yyyy \'at\' hh:mmaaa')"
          disabled
        />
      </fieldset>

      <fieldset class="mt-3 d-flex flex-column gap-3">
        <FormWrapper label="Notes">
          <EntityList
            :items="form.notes || []"
            label="Note"
            item-key="createdAt"
            can-add
            @show-add="onShowAddNote"
            @show-edit="onShowEditNote"
            @add="onAddNote"
          >
            <template #item="{ item: note }">
              <div class="d-flex flex-column w-50 flex-grow-1">
                <div class="d-flex justify-content-between align-items-center flex-grow-1">
                  <div class="px-1 fs-3">
                    <i
                      class="bi"
                      :class="
                        note.createdAt ? 'bi-justify-left' : 'bi-exclamation-triangle text-warning'
                      "
                    />
                  </div>
                  <div class="ps-2 pe-1 lh-sm fs-6 flex-grow-1 overflow-hidden">
                    <div class="text-truncate">{{ note.updatedBy || note.createdBy }}</div>
                    <div class="text-secondary text-truncate" v-if="note.createdAt">
                      {{ note.updatedAt ? 'Last updated' : 'Created' }} on
                      {{ formatISOStringDate(note.updatedAt || note.createdAt, 'dd/MM/yyyy') }}
                    </div>
                    <div v-if="!note.createdBy">Not yet saved</div>
                  </div>
                </div>
                <div class="ps-2 pe-1 lh-sm fs-6 mt-2 text-truncate">
                  {{ note.noteText }}
                </div>
              </div>
            </template>
            <template #form="{ disabled }">
              <fieldset class="d-flex flex-column gap-3">
                <FormInput
                  v-if="disabled"
                  id="createdBy"
                  label="Created By"
                  :model-value="noteForm.createdBy"
                  disabled
                />
                <FormInput
                  v-if="disabled"
                  id="createdAt"
                  label="Created At"
                  disabled
                  :model-value="
                    formatISOStringDate(noteForm.createdAt, 'EEE do MMM yyyy \'at\' hh:mmaaa')
                  "
                />
                <FormTextarea
                  label="Note Text"
                  id="noteText"
                  v-model="noteForm.noteText"
                  :disabled="disabled"
                />
                <Alert v-if="!disabled" icon="info-circle" class="my-0"
                  >Notes cannot be edited after they have been added.</Alert
                >
              </fieldset>
            </template>
          </EntityList>
        </FormWrapper>
      </fieldset>
      <div class="mt-4 d-flex gap-2">
        <button v-if="!audit?.isComplete || isResolved" class="btn btn-primary" type="submit">
          Save
        </button>
        <button
          v-if="audit?.isComplete && !isResolved(audit)"
          class="btn btn-primary"
          type="button"
          @click.prevent="onResolveCheck"
        >
          Resolve Audit
        </button>
        <button class="btn btn-outline-secondary" type="button" @click.prevent="emit('dismiss')">
          Cancel
        </button>
        <button
          v-if="audit && !audit.isComplete"
          class="btn btn-outline-danger"
          type="button"
          @click.prevent="emit('delete', form)"
        >
          Delete
        </button>
      </div>
    </form>
    <Teleport to="body">
      <Modal
        ref="modalEl"
        title="Confirm Resolve Audit"
        :on-save="onResolveConfirm"
        save-text="Confirm"
      >
        <template #form
          >Are you sure you wish to mark the audit as resolved? You cannot undo this
          action.</template
        >
      </Modal>
    </Teleport>
  </DrawerOverlay>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import useVuelidate from '@vuelidate/core';
import { helpers, requiredIf } from '@vuelidate/validators';
import { cloneDeep, pick } from 'lodash-es';
import { useApi } from '../stores/api';
import { useAppStore } from '../stores/app';
import { formatISOStringDate } from '../utilities/date';
import Alert from './Alert.vue';
import EntityList from './EntityList.vue';
import FormCheckbox from './FormCheckbox.vue';
import FormDate from './FormDate.vue';
import FormWrapper from './FormWrapper.vue';
import FormInput from './FormInput.vue';
import FormMultiSelect from './FormMultiSelect.vue';
import FormSelect from './FormSelect.vue';
import FormTextarea from './FormTextarea.vue';
import Modal, { useModal } from './Modal.vue';
import DrawerOverlay from './DrawerOverlay.vue';

const api = useApi();
const { appData } = storeToRefs(useAppStore());

const emit = defineEmits(['create', 'update', 'delete', 'resolve', 'dismiss']);

const props = defineProps({
  project: {
    type: Object,
    required: true,
  },
  lodgement: {
    type: Object,
    default: null,
  },
  section: {
    type: String,
    required: true,
  },
  sections: {
    type: Array,
    required: true,
  },
  audit: {
    type: Object,
    default: null,
  },
  audits: {
    type: Array,
    default: () => [],
  },
  type: {
    type: String,
    default: '',
  },
});

// Documents
const documents = computed(() =>
  props.section === 'documents'
    ? props.project.documents
        .filter((d) => !d.lodgementId)
        .map((d) => ({
          value: d.documentId,
          label: `${d.documentType} ${d.filename ? `(${d.filename})` : ''}`,
        }))
    : null
);
const existingDocumentAuditIds = computed(() =>
  props.audits?.map((a) => a.whatSupportingDocumentId).filter((a) => a)
);

const documentsAudited = computed(() => {
  return documents.value?.filter((d) => existingDocumentAuditIds.value.includes(d.value));
});
const documentsUnaudited = computed(() => {
  return documents.value?.filter((d) => !existingDocumentAuditIds.value.includes(d.value));
});

// Notes
const noteForm = ref({});

const onShowAddNote = ({ next }) => {
  noteForm.value = {
    noteText: '',
  };
  next('Add Note');
};

const onShowEditNote = ({ index, next }) => {
  const note = form.value.notes[index];
  if (note) {
    noteForm.value = cloneDeep(note);
    next('View Note');
  }
};

const onAddNote = ({ next }) => {
  form.value.notes = [...(form.value.notes || []), { ...noteForm.value }];
  next();
};

// Failures
const failureReasonsLabel = computed(() => {
  const outcome = form.value.outcome?.toLowerCase() || '';
  if (outcome.includes('non compliant')) {
    return 'Reason for Non-Compliance';
  }
  return 'Reason for Failure';
});

const auditFailures = computed(() => {
  const {
    auditFailures,
    auditFailuresAssessmentDesktop,
    auditFailuresAssessmentRemote,
    auditFailuresDesignDesktop,
    auditFailuresDesignRemote,
    auditFailuresInsuranceGuarantee,
    auditFailuresPredesignBuildingSurvey,
    auditFailuresRiskAssessment,
  } = appData.value;

  if (props.type === 'project-documents') {
    const document = props.project.documents.find(
      (d) => d.documentId === form.value.whatSupportingDocumentId
    );
    if (document?.documentType === 'Insurance guarantee') {
      return auditFailuresInsuranceGuarantee;
    } else if (document?.documentType === 'Pre-Design Building Survey') {
      return auditFailuresPredesignBuildingSurvey;
    }
  }
  if (props.type === 'project-assessment') {
    if (form.value.type === 'Desktop') {
      return auditFailuresAssessmentDesktop;
    } else {
      return auditFailuresAssessmentRemote;
    }
  }
  if (props.type.indexOf('project-design') > -1) {
    if (form.value.type === 'Desktop') {
      return auditFailuresDesignDesktop;
    } else {
      return auditFailuresDesignRemote;
    }
  }
  if (props.type === 'project-risk') {
    return auditFailuresRiskAssessment;
  }
  return auditFailures;
});

// Form
const form = ref({});

const rules = computed(() => {
  return {
    whatSupportingDocumentId: {
      required: helpers.withMessage('Document is required', requiredIf(!!documents.value)),
    },
    auditDate: {
      required: helpers.withMessage('Date is required', requiredIf(form.value.isComplete)),
    },
    type: {
      required: helpers.withMessage('Type is required', requiredIf(form.value.isComplete)),
    },
    reason: {
      required: helpers.withMessage('Reason is required', requiredIf(form.value.isComplete)),
    },
    outcome: {
      required: helpers.withMessage('Outcome is required', requiredIf(form.value.isComplete)),
    },
    failureReasons: {
      required: helpers.withMessage('A Failure Reason is required', (value) => {
        if (!form.value.isComplete) return true;
        if (form.value.outcome === 'Pass') return true;
        return !!value?.length;
      }),
    },
  };
});

const v$ = useVuelidate(rules, form);

const onSubmit = async () => {
  const valid = await v$.value.$validate();
  if (valid) {
    if ('auditId' in form.value) {
      emit('update', form.value);
    } else {
      emit('create', form.value);
    }
  }
};

const setForm = (existingAudit = {}) => {
  const route = useRoute();
  const { params } = route;
  const defaultForm = {
    auditingWhat: props.type,
    whatSection: props.section || null,
    retrofitProjectId: props.project.retrofitProjectId,
    projectReference: props.project.yourProjectReference,
    isComplete: false,
  };
  if ((props.type.split('-') || [])[0] === 'lodgement') {
    defaultForm.whatLodgementId = params.lodgementId;
  }
  if (props.section === 'measure' || props.section === 'standalone-measure') {
    defaultForm.whatMeasureId = params.measureId;
    defaultForm.whatUMR =
      props.lodgement?.measures.find((m) => m.measureId === params.measureId)?.umr || null;
  }
  if (props.section === 'design-option') {
    defaultForm.whatRetrofitDesignId = params.designId;
  }
  if (props.section === 'improvement-option') {
    defaultForm.whatImprovementOptionEvaluationId = params.optionId;
  }
  v$.value.$reset();
  form.value = { ...defaultForm, ...existingAudit };
};

const documentUrl = ref(false);
watch(
  () => form.value.whatSupportingDocumentId,
  async (documentId) => {
    try {
      if (!documentId) throw false;
      documentUrl.value = true;
      const url = await api
        .silently()
        .throwOnError()
        .getProjectDocumentDownloadUrl(props.project.retrofitProjectId, documentId, '');
      documentUrl.value = url;
    } catch {
      documentUrl.value = false;
    }
  }
);

// Resolve
const isResolved = (audit) => audit.isResolved || audit.isPass || audit.outcome === 'Pass';

const { modal, el: modalEl } = useModal();

const onResolveCheck = () => {
  modal.value.show();
};

const onResolveConfirm = async () => {
  const payload = pick(
    form.value,
    'auditId',
    'whatImprovementOptionEvaluationId',
    'whatLodgementId',
    'whatMeasureId',
    'whatRetrofitDesignId',
    'whatSupportingDocumentId'
  );
  emit('resolve', {
    payload,
    next: (callback) => {
      modalEl.value?.$el.addEventListener('hidden.bs.modal', callback);
      modal.value.hide();
    },
  });
};

setForm(props.audit);
</script>
