<template>
  <div class="modal fade" tabindex="-1" :data-bs-backdrop="backdrop">
    <div class="modal-dialog" :class="customClass">
      <div class="modal-content">
        <div class="modal-header">
          <slot name="header">
            <h5 class="modal-title">{{ title }}</h5>
            <button
              v-if="backdrop === true"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </slot>
        </div>
        <div class="modal-body">
          <slot name="form" :disabled="disabled" />
        </div>
        <div class="modal-footer">
          <slot name="footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <slot name="actions" />
            <button
              v-if="!disabled"
              type="button"
              class="btn"
              :class="danger ? 'btn-danger' : 'btn-primary'"
              @click.prevent="onSave"
            >
              {{ saveText }}
            </button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
defineProps({
  title: {
    type: String,
    default: '',
  },
  onSave: {
    type: Function,
    default: () => {},
  },
  saveText: {
    type: String,
    default: 'Save changes',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  danger: {
    type: Boolean,
    default: false,
  },
  backdrop: {
    type: String,
    default: true,
  },
  customClass: {
    type: String,
    default: '',
  },
});
</script>

<script>
import { onBeforeUnmount, onMounted, ref, shallowRef } from 'vue';
import { Modal as bootstrapModal } from 'bootstrap';
import { stringifyQuery } from 'vue-router';

export function useModal() {
  const modal = shallowRef(null);
  const el = ref(null);

  onMounted(() => {
    if (el.value?.$el) {
      modal.value = new bootstrapModal(el.value.$el);
    }
  });
  onBeforeUnmount(() => {
    modal.value?.dispose();
  });

  return { modal, el };
}
</script>
