<template>
  <div class="loader fixed-top bg-black bg-opacity-25">
    <div class="spinner-border"></div>
  </div>
</template>

<script setup>
import { useHead } from '@vueuse/head';
import { useAuthStore } from '../stores/auth';
import { useRoute, useRouter } from 'vue-router';
import { useAppStore } from '../stores/app';
import { onMounted } from 'vue';

useHead({ title: 'Comms Platform Redirect • TrustMark | Retrofit Portal' });

const auth = useAuthStore();
const appState = useAppStore();
const route = useRoute();
const router = useRouter();

onMounted(() => {
  auth
    .commsLogin(route.query.k)
    .then(() => {
      router.push({ name: 'dashboard' });
    })
    .catch(() => {
      appState.error = '500';
    });
});
</script>

<style lang="scss" scoped>
.loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $zindex-loader;
}
</style>
