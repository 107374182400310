<template>
  <div class="form-group" :class="{ required: isRequired }">
    <div class="d-flex align-items-top">
      <label v-if="props.label" :for="props.id" class="form-label">{{ props.label }}</label>
      <FormPopover v-if="props.popover" :content="props.popover" />
    </div>
    <div class="input-group" :class="{ 'has-validation': !!error }">
      <slot name="prepend" />
      <input
        :id="props.id"
        class="form-control"
        :class="{ 'is-invalid': error, [props.inputClass]: true }"
        :autocomplete="props.autocomplete"
        :type="props.type"
        :disabled="props.disabled"
        :placeholder="placeholder"
        :maxlength="maxLength"
        :list="props.datalist.length > 0 ? datalistId : null"
        :step="props.step"
        v-model="value"
      />
      <slot name="append" />
      <div v-if="error" class="invalid-feedback">{{ error }}</div>
    </div>
    <div v-if="props.description" class="form-description">
      {{ props.description }}
    </div>
    <datalist v-if="props.datalist.length > 0" :id="datalistId">
      <option v-for="d in props.datalist" :key="d">{{ d }}</option>
    </datalist>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { inputProps, inputEmits, useFormInput } from '../utilities/form';
import FormPopover from './FormPopover.vue';

const emit = defineEmits(inputEmits);
const props = defineProps({
  ...inputProps,
  autocomplete: {
    type: String,
    default: 'off',
  },
  type: {
    type: String,
    default: 'text',
  },
  placeholder: {
    type: String,
    default: '',
  },
  maxLength: {
    type: Number,
    default: 524288,
  },
  datalist: {
    type: Array,
    default: () => [],
  },
  step: {
    type: [String, Number],
    default: 'any',
  },
});

const datalistId = computed(() => {
  return props.id + '-datalist';
});

const { value, isRequired, error } = useFormInput(props, emit);
</script>
