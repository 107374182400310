<template>
  <div class="form-group" :class="{ required: isRequired && !noRequired }">
    <div class="d-flex align-items-top">
      <label v-if="props.label" :for="props.id" class="form-label">{{ props.label }}</label>
      <FormPopover v-if="props.popover" :content="props.popover" />
    </div>
    <div class="input-group" :class="{ 'has-validation': !!error }">
      <slot name="prepend" />
      <input
        :id="props.id"
        class="form-control"
        :class="{ 'is-invalid': error, 'form-control-constrain': !props.fullWidth }"
        :autocomplete="props.autocomplete"
        :disabled="props.disabled"
        :placeholder="placeholder"
        v-model="value"
        type="number"
        :step="step"
        :min="min"
        :max="max"
        @change="onChange"
      />
      <slot name="append" />
      <div v-if="error" class="invalid-feedback">{{ error }}</div>
    </div>
    <div v-if="props.description" class="form-description">
      {{ props.description }}
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { inputProps, inputEmits, useFormInput } from '../utilities/form';
import FormPopover from './FormPopover.vue';

const emit = defineEmits(inputEmits);
const props = defineProps({
  ...inputProps,
  modelValue: {
    type: Number,
    default: undefined,
  },
  autocomplete: {
    type: String,
    default: 'off',
  },
  step: {
    type: Number,
    default: undefined,
  },
  min: {
    type: Number,
    default: undefined,
  },
  max: {
    type: Number,
    default: undefined,
  },
  placeholder: {
    type: String,
    default: '',
  },
  float: {
    type: [Boolean, Number],
    default: false,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
});

const { isRequired, error } = useFormInput(props, emit);

const decimalPlaces = computed(() => {
  return typeof props.float === 'number' ? props.float : 2;
});

const value = computed({
  get() {
    return props.disabled ? props.modelValue?.toFixed(decimalPlaces.value) : props.modelValue;
  },
  set(value) {
    if (typeof value !== 'number') {
      emit('update:modelValue', undefined);
    } else {
      emit('update:modelValue', value);
    }
  },
});

const toFixedNumber = (num, digits) => {
  const pow = Math.pow(10, digits);
  return Math.round(num * pow) / pow;
};

const onChange = () => {
  if (typeof value.value !== 'number') {
    value.value = undefined;
    return;
  }
  if (!props.float && !Number.isInteger(value.value)) {
    value.value = Math.round(value.value);
  } else {
    value.value = toFixedNumber(value.value, decimalPlaces.value);
  }
  if (value.value > props.max) {
    value.value = props.max;
  } else if (value.value < props.min) {
    value.value = props.min;
  }
};
</script>

<style lang="scss" scoped>
.form-control-constrain {
  max-width: 7em;
}
</style>
