<template>
  <AppNavbar />
  <AppSidebar v-if="!isAppError" class="fixed" />
  <main class="error" :class="{ 'error-app': isAppError }">
    <div class="p-5 w-100 h-100 d-flex flex-column justify-content-center align-items-center">
      <h1 class="error-code">{{ error.status }}</h1>
      <h2 class="error-message mt-2">{{ message }}</h2>
      <RouterLink
        v-if="!isAppError"
        class="mt-4 btn btn-sm btn-outline-secondary"
        :to="{ name: 'dashboard' }"
        >Back to Dashboard</RouterLink
      >
    </div>
  </main>
</template>

<script setup>
import { computed } from 'vue';
import AppNavbar from './AppNavbar.vue';
import AppSidebar from './AppSidebar.vue';

const props = defineProps({
  error: {
    type: Error,
    required: true,
  },
});

const isAppError = computed(() => props.error?.status === 'ERR_NETWORK');

const message = computed(() => {
  return (
    props.error.message ||
    {
      404: 'Page not found',
      403: 'Page is inaccessible',
      500: 'Something went wrong',
      ERR_NETWORK: 'Please check you are connected to the internet',
    }[props.error.status] ||
    'Something went wrong'
  );
});
</script>

<style lang="scss">
main.error {
  padding-top: $navbar-height;
  height: 100vh;
}

@include media-breakpoint-up(lg) {
  main.error:not(.error-app) {
    transition: 200ms ease padding;
    padding-left: $sidebar-width;
  }
  .sidebar-minimized {
    main.error:not(.error-app) {
      padding-left: $sidebar-width-mini;
    }
  }
}

.error-code {
  font-size: 4rem;
  line-height: 1;
}

.error-message {
  font-size: $h3-font-size;
  font-weight: normal;
  line-height: 1;
}
</style>
