<template>
  <nav class="navbar bg-dark sticky-top p-0">
    <div class="d-flex w-100 h-100 justify-content-between align-items-center">
      <RouterLink class="h-90 py-4 ps-4" :to="{ name: 'dashboard' }">
        <img class="h-90 me-3" src="@/assets/trustmark_logo_light_2023.svg" style="height: 32px" />
      </RouterLink>

      <div v-if="isInactiveLicence" class="alert alert-info p-1 mt-3 ps-2 pe-2">
        This account is readonly as the licence is no longer active.
      </div>

      <a
        v-if="accountHolderName"
        class="nav-item d-flex h-100 py-2 px-3 d-none d-lg-flex"
        href="#"
        ref="accountBtn"
        :class="{ 'bg-primary': accountVisible }"
        @click.prevent="toggleAccount"
      >
        <i class="bi bi-person-circle fs-2 text-white" />
        <i
          v-if="availableAccounts && availableAccounts.length > 0"
          class="bi available-accounts fs-5"
          :class="availableAccountsNumberClass"
        />
        <div class="nav-item disabled small mx-2 text-end">
          <div class="d-flex flex-column">
            <div v-if="accountHolderName" class="fs-5 neutral">{{ accountHolderName }}</div>
            <div class="fs-6">
              {{ email
              }}<span v-if="tmln">
                - <span class="neutral-tmln">{{ tmln }} {{ licenceStatus }}</span></span
              >
            </div>
          </div>
        </div>

        <i class="bi-caret-down-fill fs-6 neutral ms-2" />
      </a>
      <a
        href="#"
        class="nav-item d-flex d-lg-none ms-auto py-2 px-3"
        :class="{ 'bg-primary': appState.sidebarExpanded }"
        @click.prevent="toggleSidebar"
        ><i class="fs-2" :class="menuIcon"
      /></a>
    </div>
    <AppNavbarAccount
      v-if="accountVisible"
      :dismiss-ignore="[accountBtn]"
      @dismiss="accountVisible = false"
    />
  </nav>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useAccountStore } from '../stores/account';
import { useAppStore } from '../stores/app';
import AppNavbarAccount from './AppNavbarAccount.vue';
import { useAuthStore } from '../stores/auth';

const appState = useAppStore();
const toggleSidebar = () => {
  appState.sidebarExpanded = !appState.sidebarExpanded;
};

const accountStore = useAccountStore();
const authStore = useAuthStore();
const accountVisible = ref(false);
const accountBtn = ref(null);
const toggleAccount = () => {
  appState.sidebarExpanded = false;
  accountVisible.value = !accountVisible.value;
};

const email = computed(() => authStore.account?.accountName);
const accountHolderName = computed(() => accountStore.account?.accountHolderName || 'TrustMark');
const tmln = computed(() => accountStore.account?.tmln);
const licenceStatus = computed(() => authStore.account?.licenceStatus);
const isInactiveLicence = computed(() => authStore.account?.isInactiveLicence);

const availableAccounts = computed(() => {
  const currentAccountId = accountStore.account?.retrofitAccountId;
  return authStore.account?.availableRetrofitAccountIdentities?.filter(
    (account) => account.retrofitAccountId !== currentAccountId
  );
});

const availableAccountsNumberClass = computed(() => {
  const currentAccountId = accountStore.account?.retrofitAccountId;
  const accounts = authStore.account.availableRetrofitAccountIdentities?.filter(
    (account) => account.retrofitAccountId !== currentAccountId
  );
  return `bi-${accounts.length > 9 ? 9 : accounts.length}-circle-fill`;
});

const menuIcon = computed(() => (appState.sidebarExpanded ? 'bi-x-lg' : 'bi-list'));
</script>

<style lang="scss" scoped>
.nav-item {
  height: 100%;
  align-items: center;
  justify-content: center;
  color: $white;
  text-decoration: none;
}
.navbar {
  height: $navbar-height;
  z-index: $zindex-navbar;
  position: fixed;
  top: 0;
  width: 100%;
  .nav-item.disabled {
    color: $gray-500;
  }
  .neutral {
    color: $gray-300;
    font-weight: 500;
  }
  .neutral-tmln {
    color: $gray-300;
  }
  .available-accounts {
    position: relative;
    bottom: -10px;
    right: 10px;
    color: $teal;
  }
}
</style>
