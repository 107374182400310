<template>
  <DrawerOverlay title="Flag No Access" @dismiss="emit('dismiss')">
    <form @submit.prevent="onSubmit">
      <div class="audit-section">
        <FormMultiSelect
          id="noAccessReasons"
          label="Reason for Flagging"
          :options="appData.onsiteAuditNoAccessReasonTypes"
          v-model="form.noAccessReasons"
        />
        <button class="mt-3 btn btn-danger w-100" type="submit">Confirm No Access</button>
      </div>
    </form>
  </DrawerOverlay>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useAppStore } from '../stores/app';
import FormMultiSelect from './FormMultiSelect.vue';
import DrawerOverlay from './DrawerOverlay.vue';
import { ref } from 'vue';
const emit = defineEmits(['confirm', 'dismiss']);

const appStore = useAppStore();
const { appData } = storeToRefs(appStore);

const props = defineProps({
  audit: {
    type: Object,
    required: true,
  },
});

const form = ref({ ...props.audit });

const onSubmit = async () => {
  emit('confirm', form.value.noAccessReasons);
};
</script>
