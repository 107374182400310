import { useRouter } from 'vue-router';

export const recentKeys = ['id', 'title', 'status', 'to'];

export const recentSerializer = {
  read: (v) => {
    const value = v ? JSON.parse(v) : null;
    if (!Array.isArray(value)) return [];
    const router = useRouter();
    return value.filter((recent) => {
      try {
        const hasKeys = recentKeys.every((key) => key in recent);
        const isResolvable = router.resolve(recent.to);
        return hasKeys && isResolvable;
      } catch {
        return false;
      }
    });
  },
  write: (v) => JSON.stringify(v),
};
