import useVuelidate from '@vuelidate/core';
import { computed, ref } from 'vue';
import { useAppStore } from '../stores/app';

export const scrollToError = (inputId, message) => {
  // Attempt to find the form group, or revert to the error message itself
  const element =
    document.getElementById(inputId)?.closest('.form-group') ||
    document.querySelector('.is-invalid')?.closest('.form-group') ||
    document.querySelector('.invalid-feedback');
  if (element) {
    // Get header offset
    const header = document.querySelector('.sticky-header') || document.querySelector('.navbar');
    const offset = header?.getBoundingClientRect()?.bottom || 64 + 124;
    // Get top of found element
    const { top } = element.getBoundingClientRect();
    window.scrollTo({
      left: 0,
      top: top + document.documentElement.scrollTop - offset,
      behavior: 'smooth',
    });
  } else {
    const app = useAppStore();
    app.addNotification({
      type: 'error',
      message: `There was an error in the form (${message})`,
      timeout: 5000,
    });
  }
};

export const validateForm =
  (validator, onSuccess) =>
  async (...args) => {
    const valid = await validator.value.$validate();
    if (!valid) {
      const id = validator.value?.$errors?.[0]?.$property;
      return scrollToError(id, validator.value?.$errors?.[0]?.$message);
    }
    return onSuccess(...args);
  };

export const useForm = (rules, formDefaults = {}, options = {}) => {
  const form = ref(formDefaults);
  const validator = useVuelidate(rules, form, options);
  const validate = (onSuccess) => validateForm(validator, onSuccess);
  return { form, validator, validate };
};

export const inputEmits = ['update:modelValue'];

export const inputProps = {
  id: {
    type: String,
    required: true,
  },
  modelValue: {
    type: [String, Number, Array, Object],
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
  validator: {
    type: Object,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  inputClass: {
    type: String,
    default: '',
  },
  popover: {
    type: String,
    default: '',
  },
  noRequired: {
    type: Boolean,
    default: false,
  },
};

export function useFormInput(props, emit) {
  const value = computed({
    get() {
      return props.modelValue;
    },
    set(value) {
      emit('update:modelValue', value);
    },
  });

  const isRequired = computed(() => {
    return (props.required || props.validator?.required) && !props.noRequired;
  });

  const error = computed(() => {
    return props.validator?.$errors?.[0]?.$message;
  });

  return { isRequired, value, error };
}
