import {
  createRouter as createVueRouter,
  createWebHistory,
  isNavigationFailure,
  RouterView,
} from 'vue-router';
import { useAppStore } from '../stores/app';
import { useAuthStore } from '../stores/auth';
import { useAccountStore } from '../stores/account';
import { hasFlag, hasPermission } from '../utilities/auth';
import { HttpError } from '../utilities/error';
import DashboardView from '../views/DashboardView.vue';
import LoginView from '../views/LoginView.vue';
import CommsPlatformView from '../views/CommsPlatformView.vue';
import LogoutView from '../views/LogoutView.vue';
import NotFoundView from '../views/NotFoundView.vue';

const projectRoutes = [
  {
    name: 'project',
    path: '',
    component: () => import('../views/ProjectOverviewView.vue'),
    meta: {
      audit: true,
    },
  },
  {
    name: 'project-confirm',
    path: 'confirm',
    component: () => import('../views/ProjectCreateView.vue'),
    meta: { title: 'Declaration' },
  },
  {
    name: 'project-complete',
    path: 'complete',
    component: () => import('../views/ProjectCompleteView.vue'),
    meta: { title: 'Declaration' },
  },
  {
    name: 'project-info',
    path: 'info',
    component: () => import('../views/ProjectInfoView.vue'),
    meta: {
      title: 'Information',
      audit: true,
    },
  },
  {
    name: 'project-assessment',
    path: 'assessment',
    component: () => import('../views/ProjectAssessmentView.vue'),
    meta: {
      title: 'Assessment',
      audit: true,
    },
  },
  {
    name: 'project-property',
    path: 'property',
    component: () => import('../views/ProjectPropertyView.vue'),
    meta: {
      title: 'Property Information & Tenure',
      audit: true,
    },
  },
  {
    name: 'project-defects',
    path: 'defects',
    component: () => import('../views/ProjectDefectsView.vue'),
    meta: {
      title: 'Defects',
      audit: true,
    },
  },
  {
    name: 'project-intended-outcomes',
    path: 'intended-outcomes',
    component: () => import('../views/ProjectIntendedOutcomesView.vue'),
    meta: {
      title: 'Intended Outcomes',
      audit: true,
    },
  },
  {
    path: 'evaluation',
    component: RouterView,
    meta: {
      title: 'Improvement Option Evaluations',
    },
    children: [
      {
        name: 'project-evaluation',
        path: '',
        component: () => import('../views/ProjectImprovementOptionEvaluationsView.vue'),
        meta: { audit: false },
      },
      {
        name: 'project-improvement-option',
        path: ':optionId',
        component: () => import('../views/ProjectImprovementOptionEvaluationView.vue'),
        meta: {
          title: 'Improvement Option Evaluation',
          audit: ({ project, route }) => {
            const match = project?.improvementOptionEvaluations.find(
              (ioe) => ioe.improvementOptionEvaluationId === route.params.optionId
            );
            return match?.status === 'Selected';
          },
        },
      },
    ],
  },
  {
    name: 'project-medium-term-improvement-plan',
    path: 'medium-term-improvement-plan',
    component: () => import('../views/ProjectMediumTermPlanView.vue'),
    meta: {
      title: 'Medium Term Improvement Plan',
      audit: true,
    },
  },
  {
    name: 'project-risk',
    path: 'risk',
    component: () => import('../views/ProjectRiskAssessmentView.vue'),
    meta: {
      title: 'Risk',
      audit: true,
    },
  },
  {
    path: 'design',
    component: RouterView,
    meta: { title: 'Retrofit Designs' },
    children: [
      {
        name: 'project-design',
        path: '',
        component: () => import('../views/ProjectRetrofitDesignsView.vue'),
        meta: { audit: false },
      },
      {
        name: 'project-design-option',
        path: ':designId',
        component: () => import('../views/ProjectRetrofitDesignView.vue'),
        meta: {
          title: 'Retrofit Design',
          audit: true,
        },
      },
    ],
  },
  {
    path: 'roles',
    component: RouterView,
    meta: {
      title: 'Roles',
      audit: true,
    },
    children: [
      {
        name: 'project-roles',
        path: '',
        component: () => import('../views/ProjectRolesView.vue'),
        meta: {},
      },
      {
        name: 'project-roles-response',
        path: ':responseId',
        component: () => import('../views/ProjectRolesResponseView.vue'),
        meta: {
          title: 'PAS2035 Response',
        },
      },
    ],
  },
  {
    name: 'project-documents',
    path: 'documents',
    component: () => import('../views/ProjectDocumentsView.vue'),
    meta: {
      title: 'Documents',
      audit: true,
    },
  },
  {
    name: 'project-notes',
    path: 'notes',
    component: () => import('../views/ProjectNotesView.vue'),
    meta: {
      title: 'Notes',
      audit: true,
    },
  },
  {
    name: 'project-amend',
    path: 'amend',
    component: () => import('../views/ProjectAmendView.vue'),
    meta: {
      title: 'Amend',
      amend: true,
    },
  },
  {
    name: 'project-amend-history',
    path: 'amend-history',
    component: () => import('../views/ProjectAmendHistoryView.vue'),
    meta: {
      title: 'Amend History',
      amendHistory: true,
      flags: { isTrustmark: true },
    },
  },
  {
    name: 'project-lodgements',
    path: 'lodgements',
    component: () => import('../views/ProjectLodgementsView.vue'),
    meta: { title: 'Lodgements', audit: false },
  },
  {
    name: 'project-evaluator-responses',
    path: 'evaluator-responses',
    meta: { title: 'Evaluator Responses' },
    component: RouterView,
    children: [
      {
        name: 'project-evaluation-responses',
        path: '',
        component: () => import('../views/ProjectEvaluatorResponsesView.vue'),
      },
      {
        name: 'project-evaluation-response',
        path: ':evaluatorResponseId',
        component: () => import('../views/ProjectEvaluatorResponseView.vue'),
        meta: {
          title: 'Evaluation Response',
        },
      },
    ],
  },
  {
    name: 'project-ofgem',
    path: 'ofgem',
    component: () => import('../views/ProjectOfgemView.vue'),
    meta: { title: 'Ofgem Checks History' },
  },
];

projectRoutes.push({
  name: 'project-remediation',
  path: 'remediation',
  component: () => import('../views/ProjectRemediationView.vue'),
  meta: { title: 'Remediation' },
});

const routes = [
  {
    name: 'dashboard',
    path: '/',
    // Prevent lazyload to prevent flash?
    component: DashboardView,
  },
  {
    name: 'finance',
    path: '/finance',
    component: () => import('../views/FinanceView.vue'),
    meta: {
      permissions: ['FinanceViewer'],
      flags: { isTrustmark: true },
    },
  },
  {
    path: '/account',
    component: () => import('../views/AccountView.vue'),
    meta: {
      permissions: ['RetrofitPortalAccess'],
    },
    children: [
      {
        name: 'account',
        path: '',
        component: () => import('../views/AccountProfileView.vue'),
      },
      {
        name: 'account-overview',
        path: 'overview',
        component: () => import('../views/AccountDetailsView.vue'),
      },
      {
        name: 'account-billing',
        path: 'billing',
        component: () => import('../views/AccountBillingView.vue'),
        meta: {
          permissions: ['RetrofitBillingandFinance'],
        },
      },
      {
        name: 'account-users',
        path: 'users',
        component: () => import('../views/AccountUsersView.vue'),
        meta: {
          permissions: ['RetrofitAccessManagement'],
        },
      },
      {
        name: 'account-access',
        path: 'access',
        component: () => import('../views/AccountAccessView.vue'),
        meta: {
          permissions: ['RetrofitAccessManagement'],
        },
      },
      {
        name: 'account-topup',
        path: 'topup',
        component: () => import('../views/AccountTopupView.vue'),
        meta: {
          permissions: ['RetrofitBillingandFinance'],
        },
      },
      {
        name: 'account-notifications',
        path: 'notifications',
        component: () => import('../views/AccountNotificationsView.vue'),
        meta: {
          permissions: ['RetrofitBillingandFinance'],
        },
      },
      {
        name: 'account-invoices',
        path: 'invoices',
        component: () => import('../views/AccountInvoicesView.vue'),
        meta: {
          permissions: ['RetrofitBillingandFinance'],
        },
      },
      {
        name: 'account-api',
        path: 'api',
        component: () => import('../views/AccountApiView.vue'),
        meta: {
          permissions: ['RetrofitAccessManagement'],
        },
      },
      {
        name: 'account-recents',
        path: 'recents',
        component: () => import('../views/AccountRecentsView.vue'),
      },
    ],
  },
  {
    name: 'logout',
    path: '/logout',
    component: LogoutView,
  },
  {
    name: 'login',
    path: '/login',
    component: LoginView,
    meta: {
      public: true,
    },
  },
  {
    name: 'commsplatform',
    path: '/commsplatform',
    component: CommsPlatformView,
    meta: {
      public: true,
    },
  },
  {
    name: 'register',
    path: '/register',
    component: () => import('../views/RegisterView.vue'),
    meta: {
      public: true,
    },
  },
  {
    name: 'forgot-password',
    path: '/forgot-password',
    component: () => import('../views/ForgotPasswordView.vue'),
    meta: {
      public: true,
    },
  },
  {
    name: 'legacy',
    path: '/legacy',
    component: () => import('../views/LegacyAccessView.vue'),
    meta: {
      public: true,
      legacy: true,
    },
  },
  {
    path: '/assessments',
    component: RouterView,
    meta: {
      flags: {
        isRetrofitCoordinator: true,
        isRetroAssessor: true,
        isTrustmark: true,
      },
      permissions: ['RetrofitSubmissionofData', 'RetrofitAssessmentViewer'],
    },
    children: [
      {
        name: 'assessments',
        path: '',
        component: () => import('../views/AssessmentsView.vue'),
      },
      {
        name: 'assessment-new',
        path: 'new',
        component: () => import('../views/AssessmentNewView.vue'),
      },
      {
        path: ':assessmentId',
        component: () => import('../views/AssessmentView.vue'),
        children: [
          {
            name: 'assessment',
            path: '',
            component: () => import('../views/AssessmentFormView.vue'),
          },
          {
            name: 'assessment-confirm',
            path: 'confirm',
            component: () => import('../views/AssessmentConfirmView.vue'),
            meta: { title: 'Declaration' },
          },
        ],
      },
    ],
  },
  {
    path: '/lodgements',
    component: RouterView,
    meta: {
      permissions: ['RetrofitSubmissionofData', 'RetrofitProjectViewer'],
    },
    children: [
      {
        name: 'lodgements',
        path: '',
        component: () => import('../views/LodgementsView.vue'),
      },
      {
        name: 'lodgements-legacy',
        path: 'legacy',
        component: () => import('../views/LodgementsLegacyView.vue'),
      },
      {
        name: 'lodgement-new',
        path: 'new',
        component: () => import('../views/LodgementNewView.vue'),
      },
      {
        path: 'standalone/:projectId/:lodgementId',
        component: () => import('../views/LodgementView.vue'),
        children: [
          {
            name: 'lodgement-standalone',
            path: '',
            component: () => import('../views/StandaloneLodgementOverviewView.vue'),
            meta: {
              audit: ({ lodgement }) => lodgement?.isComplete === true,
            },
          },
          {
            name: 'lodgement-standalone-measure',
            path: 'measure/:measureId',
            component: () => import('../views/LodgementMeasureView.vue'),
            meta: {
              title: 'Measure',
              audit: ({ lodgement }) => lodgement?.isComplete === true,
            },
          },
          {
            name: 'lodgement-standalone-confirm',
            path: 'confirm',
            component: () => import('../views/LodgementCreateView.vue'),
            meta: {
              title: 'Declaration',
            },
          },
          {
            name: 'lodgement-standalone-amend',
            path: 'amend',
            component: () => import('../views/LodgementAmendView.vue'),
            meta: {
              title: 'Amend',
            },
          },
          {
            name: 'lodgement-standalone-remediation',
            path: 'remediation',
            component: () => import('../views/LodgementRemediationView.vue'),
            meta: {
              title: 'Remediation',
            },
          },
        ],
      },
      {
        path: ':projectId/:lodgementId',
        component: () => import('../views/LodgementView.vue'),
        children: [
          {
            name: 'lodgement',
            path: '',
            component: () => import('../views/LodgementOverviewView.vue'),
            meta: {
              audit: ({ lodgement }) => lodgement?.isComplete === true,
            },
          },
          {
            name: 'lodgement-measure',
            path: 'measure/:measureId',
            component: () => import('../views/LodgementMeasureView.vue'),
            meta: {
              title: 'Measure',
              audit: ({ lodgement }) => lodgement?.isComplete === true,
            },
          },
          {
            name: 'lodgement-confirm',
            path: 'confirm',
            component: () => import('../views/LodgementCreateView.vue'),
            meta: {
              title: 'Declaration',
            },
          },
          {
            name: 'lodgement-amend',
            path: 'amend',
            component: () => import('../views/LodgementAmendView.vue'),
            meta: {
              title: 'Amend',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/projects',
    component: RouterView,
    meta: {
      permissions: ['RetrofitSubmissionofData', 'RetrofitProjectViewer', 'RetrofitProjectEditor'],
      flags: { isRetrofitCoordinator: true, isTrustmark: true },
    },
    children: [
      {
        name: 'projects',
        path: '',
        component: () => import('../views/ProjectsView.vue'),
      },
      {
        name: 'project-new',
        path: 'new',
        component: () => import('../views/ProjectNewView.vue'),
      },
      {
        path: ':projectId',
        component: () => import('../views/ProjectView.vue'),
        children: projectRoutes,
      },
    ],
  },
  {
    name: 'exports',
    path: '/exports',
    component: () => import('../views/ExportsView.vue'),
    meta: {
      permissions: ['RetrofitExportRequest'],
    },
  },
  {
    path: '/responses',
    component: RouterView,
    children: [
      {
        name: 'responses',
        path: '',
        component: () => import('../views/PASResponsesView.vue'),
      },
      {
        path: ':projectId/:responseId',
        component: () => import('../views/PASResponseView.vue'),
        children: [
          {
            name: 'response',
            path: '',
            component: () => import('../views/PASResponseFormView.vue'),
          },
          {
            name: 'response-design',
            path: 'design',
            component: () => import('../views/PASResponseDesignView.vue'),
            meta: { title: 'Design' },
          },
          {
            name: 'response-assessment',
            path: 'assessment',
            component: () => import('../views/PASResponseAssessmentView.vue'),
            meta: { title: 'Assessment' },
          },
        ],
      },
    ],
  },
  {
    name: 'not-found',
    path: '/:pathMatch(.*)*',
    component: NotFoundView,
  },
];

routes.push({
  path: '/compliance',
  component: () => import('../views/ComplianceView.vue'),
  meta: {
    permissions: ['OnsiteAuditViewer'],
  },
  children: [
    {
      name: 'compliance',
      path: '',
      component: () => import('../views/ComplianceAppointmentsView.vue'),
    },
    {
      name: 'compliance-visit-logs',
      path: 'visit-logs',
      component: () => import('../views/ComplianceOnsiteAuditVisitLogsView.vue'),
    },
    {
      name: 'compliance-question-sets',
      path: 'question-sets',
      component: () => import('../views/ComplianceQuestionSetsView.vue'),
      children: [
        {
          name: 'compliance-question-set',
          path: ':questionSetId',
          component: () => import('../views/ComplianceQuestionSetsReviewView.vue'),
        },
      ],
    },
  ],
});

routes.push({
  name: 'ofgem',
  path: '/ofgem',
  component: () => import('../views/OfgemView.vue'),
  meta: {
    permissions: ['OfgemAdmin'],
    flags: { isTrustmark: true },
  },
});

routes.push({
  name: 'uprn-search',
  path: '/uprn',
  component: () => import('../views/UPRNSearchView.vue'),
  meta: {
    permissions: ['UPRNSearch'],
    flags: { isTrustmark: true },
  },
});

export const createRouter = () => {
  const auth = useAuthStore();
  const appState = useAppStore();
  const accountStore = useAccountStore();

  const router = createVueRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
    linkActiveClass: 'link-active',
    linkExactActiveClass: 'link-active-exact',
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { top: 0 };
      }
    },
  });

  router.beforeEach(async (to, from) => {
    try {
      const { meta } = to;
      // Allow legacy token use
      if (meta.legacy) return true;
      if (to.name === 'register' && to.query.email && to.query.token) {
        // Legacy portal user register
        return true;
      }
      if (to.name === 'login' && from.name === 'register' && from.query.email && from.query.token) {
        // Block login redirect from legacy register
        return false;
      }
      if (to.name === 'commsplatform' && to.query.k) {
        return true;
      }

      // Public routes are auth pages
      const isPublic = meta.public === true;
      let loggedIn = !!auth.account;

      // Logout on using register invite
      if (loggedIn && to.name === 'register') {
        await auth.logout();
        loggedIn = false;
      }

      // Fetch app data if it doesn't exist
      if (loggedIn && !appState.appData) {
        const requests = [];
        if (!appState.appData) {
          requests.push(appState.init());
        }
        if (!accountStore.account) {
          requests.push(accountStore.init());
        }
        await Promise.all(requests);
      }

      // Redirect to login if accessing private route
      if (!isPublic && !loggedIn) {
        return { name: 'login' };
      }

      // If logged in and try to access an auth route, redirect
      if (isPublic && loggedIn) {
        return { name: 'dashboard' };
      }

      // If route requires permission, and user doesn't have it
      // Redirect to dashboard
      if (loggedIn && (meta.permissions || meta.flags)) {
        const results = [
          hasFlag(appState.appData.flags, meta.flags),
          hasPermission(auth.account?.roles, meta?.permissions),
        ];

        if (!results.every((r) => r === true || r === null)) {
          return { name: 'dashboard' };
        }
      }

      if (
        loggedIn &&
        !auth.account?.acceptedRetrofitTAndCs &&
        to.query?.showRetrofitTAndCs !== 'y' &&
        to.name !== 'logout'
      ) {
        return { name: 'dashboard', query: { showRetrofitTAndCs: 'y' } };
      }

      // Maintain query string parameters on lodgements navigated to from a project
      if (to.name.startsWith('lodgement') && from.query.returnTo && !to.query.returnTo) {
        return { ...to, query: from.query };
      }
      return true;
    } catch (e) {
      appState.error = new HttpError(e.response?.status === 0 ? e.code : e.response?.status);
      return false;
    }
  });

  router.afterEach(async (to, from, failure) => {
    if (!isNavigationFailure(failure)) {
      // Reset state
      appState.error = null;
      appState.sidebarExpanded = false;
      // Bootstrap override classes
      // In case user navigates whilst modal is open
      document.body?.classList.remove('modal-open');
      document.body?.removeAttribute('style');
    }
    if (to.name === 'logout') {
      // Need to capture variable before logout otherwise the state is wiped
      const thirdPartyIntegration = accountStore.account?.thirdPartyIntegration;
      try {
        await auth.logout();
      } catch {
        // @TODO Do nothing?
      } finally {
        if (thirdPartyIntegration === 'CommsPlatform') {
          window.location.href = 'https://www.trustmark.org.uk/dashboard';
        }
      }
    }
  });

  // New builds will sometimes cause current users with cached versions to request components no longer exist. Force a hard refresh if that happens
  router.onError((error) => {
    console.log(error);
    if (error.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload(true);
    }
  });

  return router;
};
