<template>
  <slot
    name="header"
    :current-index="index"
    :has-answer="hasAnswer"
    :goto="goto"
    :next="next"
    :prev="prev"
  />

  <div :class="wrapClass">
    <div class="form-label mb-0 text-secondary">{{ props.title }}</div>
    <div v-if="subtitle" class="form-label mb-0 text-secondary">{{ props.subtitle }}</div>
    <Transition :name="`question-${direction}`" mode="out-in">
      <Question
        :key="question.questionId"
        :disabled="props.disabled"
        :review="props.review"
        :questions="props.questions"
        v-bind="question"
        @update="(answer) => emit('update:answer', { id: question.questionId, answer })"
      />
    </Transition>

    <div class="btn-group w-100">
      <button
        class="w-100 btn btn-sm btn-outline-primary"
        type="button"
        :disabled="index === 0"
        @click.prevent="prev"
      >
        <i class="bi-chevron-left me-1" />Prev
      </button>
      <button class="w-100 btn btn-sm btn-outline-primary" type="button" @click="showList = true">
        <i class="bi-list me-1" />
        List
      </button>
      <button
        class="w-100 btn btn-sm btn-outline-primary"
        type="button"
        :disabled="isLast && (disabled || !canComplete)"
        @click.prevent="next"
      >
        {{ isLast && !disabled ? 'Review' : 'Next' }}<i class="bi-chevron-right ms-1" />
      </button>
    </div>
  </div>

  <slot
    name="footer"
    :current-index="index"
    :has-answer="hasAnswer"
    :goto="goto"
    :next="next"
    :prev="prev"
  />

  <Transition name="swipe-right">
    <QuestionnaireList
      v-if="showList"
      :questions="props.questions"
      @goto="goto"
      @dismiss="showList = false"
    />
  </Transition>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import Question from './Question.vue';
import QuestionnaireList from './QuestionnaireList.vue';
import { questionHasAnswer } from '../utilities/compliance';

const emit = defineEmits(['review', 'update:answer', 'update:index']);
const props = defineProps({
  wrapClass: {
    type: [String, Array, Object],
    default: '',
  },
  title: {
    type: String,
    default: 'Questionnaire',
  },
  subtitle: {
    type: String,
    default: '',
  },
  questions: {
    type: Array,
    default: () => [],
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  canComplete: {
    type: Boolean,
    default: false,
  },
  review: {
    type: Boolean,
    default: false,
  },
});

const index = ref(0);
const question = computed(() => props.questions?.[index.value]);
const hasAnswer = computed(() => {
  return questionHasAnswer(question.value);
});

const isLast = computed(() => index.value === props.questions.length - 1);

const isVisibleQuestion = (question) => {
  if (question.isConditional) {
    const conditionQuestion = props.questions.find(
      (q) => q.questionId === question.conditionQuestionId
    );
    return conditionQuestion?.answer === question.conditionAnswer;
  }
  return true;
};

const next = () => {
  const nextQuestions = props.questions.slice(index.value + 1);
  const nextValidQuestionIndex = nextQuestions.findIndex(isVisibleQuestion);
  if (nextValidQuestionIndex < 0) {
    emit('review');
  } else {
    index.value = index.value + (nextValidQuestionIndex + 1);
  }
};
const prev = () => {
  const prevQuestions = [...props.questions.slice(0, index.value)].reverse();
  const prevValidQuestionIndex = prevQuestions.findIndex(isVisibleQuestion);
  index.value = index.value - (prevValidQuestionIndex + 1);
};
const goto = (i) => {
  index.value = i;
  showList.value = false;
};

const direction = ref('next');
watch(index, (nextIndex, prevIndex) => {
  if (nextIndex > prevIndex) direction.value = 'next';
  else direction.value = 'prev';
  emit('update:index', nextIndex);
});

const showList = ref(false);
</script>
