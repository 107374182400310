import { defineStore } from 'pinia';

export const useAuditStore = defineStore({
  id: 'audit',
  state: () => ({
    active: false,
    view: 'desktop',
    onsite: null,
    desktop: null,
    bespoke: null,
  }),
});
