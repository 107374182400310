<template>
  <RouterLink class="evidence-thumb" :to="link">
    <div class="d-flex align-items-center">
      <div class="ps-2 pe-1 overflow-hidden">
        <div class="fs-6 fw-bold text-secondary">
          {{ projectReference || 'No reference' }}
        </div>
        <div v-if="filename" class="text-secondary fs-6 text-nowrap text-truncate">
          {{ filename }}
        </div>
        <div class="text-secondary fs-6 text-nowrap text-truncate">Created by {{ createdBy }}</div>
        <div class="text-secondary fs-6 text-nowrap text-truncate">
          {{ createdAt }}
        </div>
      </div>
    </div>
  </RouterLink>
</template>

<script setup>
import { formatDistanceToNow } from 'date-fns';
import { computed } from 'vue';

const props = defineProps({
  retrofitProjectId: { type: String, required: true },
  remediationEvidenceId: { type: String, required: true },
  remediationId: { type: String, required: true },
  filename: { type: String, required: true },
  lodgementId: { type: String, default: undefined },
  projectReference: { type: String, default: '' },
  status: { type: String, required: true },
  createdAt: { type: String, default: undefined },
  createdBy: { type: String, default: undefined },
});

const createdAt = computed(() => {
  if (props.createdAt)
    return `Created ${formatDistanceToNow(new Date(props.createdAt), { addSuffix: true })}`;
  return 'Not yet updated';
});

const link = computed(() => {
  if (props.lodgementId) {
    return {
      name: 'lodgement-standalone-remediation',
      params: { projectId: props.retrofitProjectId, lodgementId: props.lodgementId },
    };
  }
  return { name: 'project-remediation', params: { projectId: props.retrofitProjectId } };
});
</script>

<style lang="scss" scoped>
.evidence-thumb {
  background-color: $light;
  border-radius: 0.25rem;
  border: 1px solid $border-color;
  display: block;
  overflow: hidden;
  padding: 0.5rem;
  text-decoration: none;
  transition: 200ms ease box-shadow;
  &:hover {
    box-shadow: $box-shadow-sm;
  }
}
</style>
