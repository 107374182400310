<template>
  <AppNavbar />
  <AppSidebar class="fixed" />
  <main class="content">
    <RouterView />
    <AuditDrawer v-if="audit.active" />
  </main>
</template>

<script setup>
import { useAuditStore } from '../stores/audit';
import AppNavbar from './AppNavbar.vue';
import AppSidebar from './AppSidebar.vue';
import AuditDrawer from './AuditDrawer.vue';

const audit = useAuditStore();
</script>

<style lang="scss">
main.content {
  padding-top: $navbar-height;
  min-height: 100vh;
  overflow: clip;
}

@include media-breakpoint-up(lg) {
  main.content {
    transition: 200ms ease padding;
    padding-left: $sidebar-width;
  }
  .sidebar-minimized {
    main.content {
      padding-left: $sidebar-width-mini;
    }
  }
  .drawer-active {
    main.content {
      padding-right: $drawer-width;
    }
  }
}
</style>
