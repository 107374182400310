<template>
  <div class="flex-grow-1">
    <div v-for="(question, i) of questions" :key="question.questionId" class="audit-section">
      <div class="form-label mb-1 lh-sm">{{ i + 1 }}. {{ question.text }}</div>
      <div class="text-secondary d-flex justify-content-between">
        <div class="text-break">{{ displayAnswer(question) }}</div>
        <div v-if="question.type == 'File'">
          <button
            v-if="hasFile(question.answer)"
            aria-label="Download file"
            class="btn btn-outline-neutral text-primary"
            type="button"
            @click.prevent="onDownload(question)"
          >
            <i class="bi bi-download" />
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="canRestart && confirmModal"
    class="btn-group w-100 audit-section gap-2 d-flex flex-column"
  >
    <button
      class="w-100 btn btn-sm btn-outline-primary"
      type="button"
      @click.prevent="confirmModal.show()"
    >
      Restart Form
    </button>
  </div>
  <Teleport to="body">
    <Modal ref="confirmModalEl" title="Restart Form" save-text="Restart">
      <template #form>Are you sure you want to restart the form?</template>
      <template #footer>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button
          type="button"
          class="btn btn-primary"
          @click.prevent="
            emit('restart', {});
            confirmModal.hide();
          "
        >
          Restart
        </button>
      </template>
    </Modal>
  </Teleport>
</template>

<script setup>
import { useApi } from '../stores/api';
import Modal, { useModal } from './Modal.vue';

const emit = defineEmits(['restart']);

defineProps({
  questions: {
    type: Array,
    required: true,
  },
  canRestart: {
    type: Boolean,
    default: false,
  },
});

const { modal: confirmModal, el: confirmModalEl } = useModal();

const getFileAnswerText = (answer) => {
  const q = JSON.parse(answer);
  let text = '';
  if (q && q.href) {
    text = q.title + ' ';
  }
  if (q && q.caption) {
    text += q.caption;
  }
  return text;
};

const displayAnswer = (question) => {
  if (question.type === 'File') return getFileAnswerText(question.answer);
  return question.answer;
};

const onDownload = async (question) => {
  const api = useApi();
  const q = JSON.parse(question.answer);
  const result = await api.getPresignedAuditUrl({
    link: q.href,
    projectOrAssessmentId: '',
  });
  if (result && typeof result === 'string') {
    const link = document.createElement('a');
    link.href = result;
    link.setAttribute('download', q.title);
    link.setAttribute('target', '_blank');
    link.setAttribute('rel', 'noopener noreferrer');
    // link.setAttribute('type', 'text/xml');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

const hasFile = (answer) => {
  const q = JSON.parse(answer);
  return q && q.href;
};
</script>
