import { useAppStore } from '../stores/app';

export const showNotification = (message, error = false) => {
  const appState = useAppStore();
  appState.addNotification({
    type: error ? 'error' : 'success',
    message: message,
    timeout: 5000,
  });
};
