<template>
  <div class="form-group" :class="{ required: isRequired }">
    <div class="d-flex align-items-top">
      <label v-if="props.label" :for="props.id" class="form-label">{{ props.label }}</label>
      <FormPopover v-if="props.popover" :content="props.popover" />
    </div>
    <div class="input-group" :class="{ 'has-validation': !!error }">
      <slot name="prepend" />
      <input
        :id="props.id"
        class="form-control"
        :class="{ 'is-invalid': error }"
        :autocomplete="props.autocomplete"
        :disabled="props.disabled"
        :placeholder="placeholder"
        v-model="value"
        type="date"
      />
      <slot name="append" />
      <div v-if="error" class="invalid-feedback">{{ error }}</div>
    </div>
    <div v-if="props.description" class="form-description">
      {{ props.description }}
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { format, isValid, parse, parseISO } from 'date-fns';
import { dateToISOString, formatDate } from '../utilities/date';
import { inputProps, inputEmits, useFormInput } from '../utilities/form';
import FormPopover from './FormPopover.vue';

const emit = defineEmits(inputEmits);
const props = defineProps({
  ...inputProps,
  modelValue: {
    type: [Date, String],
    default: undefined,
  },
  autocomplete: {
    type: String,
    default: 'off',
  },
  placeholder: {
    type: String,
    default: '',
  },
  endOfDay: {
    type: Boolean,
    default: false,
  },
});

const { isRequired, error } = useFormInput(props, emit);

// Date input returns in yyyy-MM-dd format
// This converts to ISO string
const value = computed({
  get() {
    try {
      const date =
        typeof props.modelValue === 'string' ? parseISO(props.modelValue) : props.modelValue;
      return formatDate(date, 'yyyy-MM-dd');
    } catch {
      return '';
    }
  },
  set(value) {
    const date = parse(value, 'yyyy-MM-dd', new Date());
    if (isValid(date)) {
      const isoDateStr = props.endOfDay
        ? format(date, "yyyy-MM-dd'T23:59:59'")
        : dateToISOString(date);
      emit('update:modelValue', isoDateStr);
    } else {
      emit('update:modelValue', null);
    }
  },
});
</script>
