<template>
  <div
    class="toast show align-items-center border-0 m-1 pe-auto"
    :class="theme"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
    @click.prevent="emit('dismiss')"
  >
    <div class="d-flex">
      <div class="toast-body">
        <i v-if="icon" class="bi me-1" :class="`bi-${icon}`" />
        {{ message }}
      </div>
      <button
        type="button"
        class="btn-close btn-close-white me-2 m-auto flex-shrink-0"
        aria-label="Close"
        @click.prevent="emit('dismiss')"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const emit = defineEmits(['dismiss']);
const props = defineProps({
  message: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: '',
  },
  icon: {
    type: String,
    default: '',
  },
  hideIcon: {
    type: Boolean,
    default: false,
  },
});

const theme = computed(() => {
  if (props.type === 'error') return 'bg-danger text-white ';
  if (props.type === 'warn') return 'bg-warning text-white ';
  return 'bg-primary text-white';
});

const icon = computed(() => {
  if (props.hideIcon) return '';
  if (props.icon) return props.icon;
  if (props.type === 'error') return 'exclamation-triangle';
  if (props.type === 'warn') return 'exclamation-triangle';
  if (props.type === 'info') return 'info-circle';
  return '';
});
</script>
