export const getLocation = () => {
  return new Promise((resolve) => {
    navigator.geolocation.getCurrentPosition(
      (r) => {
        resolve({
          success: true,
          coords: r.coords,
          timestamp: r.timestamp,
        });
      },
      () => {
        resolve({
          success: false,
          timestamp: 0,
          coords: {
            longitude: 0,
            latitude: 0,
          },
        });
      },
      { enableHighAccuracy: true }
    );
  });
};
