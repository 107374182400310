<template>
  <div class="drawer-overlay">
    <div class="drawer-underlay" @click="dismiss" />
    <div class="drawer-content">
      <div class="pt-2 ps-3 pe-2 d-flex align-items-center justify-content-between gap-2">
        <h5 v-if="title" class="lh-1 mb-0">{{ title }}</h5>
        <slot name="header" />
        <button class="btn drawer-close bi-x-lg" @click.prevent="dismiss" />
      </div>
      <slot :dismiss="dismiss" />
    </div>
  </div>
</template>

<script setup>
const emit = defineEmits(['dismiss']);
const dismiss = () => {
  emit('dismiss');
};
defineProps({
  title: {
    type: String,
    default: '',
  },
});
</script>

<style lang="scss">
.drawer-overlay {
  z-index: 10;
  position: fixed;
  top: $navbar-height + $page-header-height;
  margin-top: 1px;
  right: 0;
  width: $drawer-width;
  padding-left: 1.5rem;
  bottom: 0;
}

.drawer-underlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: pointer;
  &:hover + .drawer-content {
    transform: translateX(0.5em);
  }
}

.drawer-close {
  color: $primary;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size-base;
}
</style>
