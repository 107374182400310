export const getAddressString = (a) => {
  let s = '';
  if (a.number) {
    s += `${a.number} `;
  }
  if (a.address1) {
    s += `${a.address1},\r\n`;
  }
  if (a.address2) {
    s += `${a.address2},\r\n`;
  }
  if (a.address3) {
    s += `${a.address3},\r\n`;
  }
  if (a.city) {
    s += `${a.city}\r\n`;
  }
  if (a.postcode) {
    s += `${a.postcode}`;
  }
  return s;
};

export const formatAddressSingleLine = (a) => {
  if (!a) {
    return '';
  }
  return [a.number, a.address1, a.address2, a.address3, a.city, a.postcode]
    .filter((i) => i)
    .join(', ');
};

export const formatAddressSearchSingleLine = (a) => {
  if (!a) {
    return '';
  }
  return [a.line1, a.line2, a.line3, a.town, a.postcode].filter((i) => i).join(', ');
};

export const formatAssessmentAddressSingleLine = (a) => {
  if (!a) {
    return '';
  }
  return [a.addressLine1, a.addressLine2, a.addressLine3, a.addressTown, a.addressPostcode]
    .filter((i) => i)
    .join(', ');
};
