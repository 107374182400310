<template>
  <div class="form-group" :class="{ required: isRequired }">
    <div class="form-check" :class="{ 'has-validation': !!error }">
      <input
        :id="props.id"
        class="form-check-input"
        :class="{ 'is-invalid': error }"
        type="checkbox"
        :disabled="props.disabled"
        v-model="value"
      />
      <label v-if="props.label" :for="props.id" class="form-check-label"
        >{{ props.label }}<FormPopover v-if="props.popover" :content="props.popover"
      /></label>
      <div v-if="error" class="invalid-feedback">{{ error }}</div>
    </div>
    <div v-if="props.description" class="form-description">
      {{ props.description }}
    </div>
  </div>
</template>

<script setup>
import { inputProps, inputEmits, useFormInput } from '../utilities/form';
import FormPopover from './FormPopover.vue';

const emit = defineEmits(inputEmits);
const props = defineProps({
  ...inputProps,
  modelValue: {
    type: Boolean,
    default: undefined,
  },
});

const { value, isRequired, error } = useFormInput(props, emit);
</script>
