import { useLocalStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { recentSerializer } from '../utilities/recent';

export const idKey = 'retrofitProjectId';

export const toRecent = (project) => {
  const id = project[idKey];
  const title = project.yourProjectReference;
  const status = project.status;
  const to = { name: 'project', params: { projectId: id } };
  return { id, title, status, to };
};

export const useProjectsStore = defineStore({
  id: 'projects',
  state: () => ({
    project: null,
    projects: null,
    projectCache: {},
    searchParams: {
      term: '',
      pageSize: 12,
      page: 0,
      totalPages: 0,
      totalItems: 0,
    },
    lodgements: null,
    recentlyViewed: useLocalStorage('projects-recent', [], { serializer: recentSerializer }),
  }),
  getters: {
    isDraft: (state) => !!(state.project?.status === 'Draft'),
    isInProgress: (state) => !!(state.project?.status === 'InProgress'),
    isComplete: (state) => !!(state.project?.status === 'Complete'),
    isVoid: (state) => !!(state.project?.status === 'Void'),
    isPAS2019: (state) => !!(state.project?.pasVersion === 'PAS2035 [2019]'),
    isPAS2023: (state) => !!(state.project?.pasVersion === 'PAS2035 [2023]'),
  },
  // Individual
  actions: {
    get(id) {
      return this.projectCache[id];
    },
    cache(id, project) {
      this.projectCache[id] = project;
      this.refreshRecents([project]);
      return this;
    },
    set(id) {
      const cached = this.projectCache[id];
      if (!cached) throw new Error(`Project with ID “${id}” not found`);
      this.project = cached;
      return this;
    },
    unset() {
      this.project = null;
      return this;
    },
    unlist(id) {
      this.projects = this.projects?.filter((project) => project[idKey] !== id) || null;
      return this;
    },
    uncache(id) {
      delete this.projectCache[id];
      return this;
    },
    remove(id) {
      this.uncache(id);
      this.unlist(id);
      this.removeRecent(id);
      return this;
    },
    // List
    setList(projects) {
      this.projects = projects;
      this.refreshRecents(projects);
      return this;
    },
    // Recent
    addRecent(id) {
      const project = this.get(id);
      if (project) {
        // If it already exists in the array, remove it
        this.removeRecent(id);
        // Push entry to the start of the array
        this.recentlyViewed.unshift(toRecent(project));
        // Limit the number of results
        const maxLength = 3;
        this.recentlyViewed.length = Math.min(this.recentlyViewed.length, maxLength);
      }
      return this;
    },
    removeRecent(id) {
      const index = this.recentlyViewed.findIndex((recent) => recent.id === id);
      if (index > -1) this.recentlyViewed.splice(index, 1);
      return this;
    },
    refreshRecents(projects) {
      this.recentlyViewed.forEach((recent) => {
        const match = projects.find((p) => p[idKey] === recent.id);
        if (match) recent = toRecent(match);
      });
      return this;
    },
    clearRecents() {
      this.recentlyViewed.splice(0);
    },
  },
});
