<template>
  <DrawerOverlay title="Review Form" @dismiss="onDismiss">
    <form @submit.prevent="onSubmit">
      <div class="audit-section">
        <Alert class="mb-0" type="warning" icon>
          Please verify the provided information is correct before completing the form.
        </Alert>
      </div>

      <AuditOnsiteQuestionnaireReadonly :questions="questions" />

      <div class="audit-section">
        <button class="btn btn-primary w-100" type="submit" :disabled="isBusy">
          Confirm Complete
          <span v-if="isBusy" class="ms-1 spinner-border spinner-border-sm align-text-bottom" />
        </button>
      </div>
    </form>
  </DrawerOverlay>
</template>

<script setup>
import { ref } from 'vue';
import Alert from './Alert.vue';
import DrawerOverlay from './DrawerOverlay.vue';
import AuditOnsiteQuestionnaireReadonly from './AuditOnsiteQuestionnaireReadonly.vue';

const emit = defineEmits(['complete', 'dismiss']);

defineProps({
  questions: {
    type: Array,
    required: true,
  },
});

const isBusy = ref(false);
const onSubmit = () => {
  isBusy.value = true;
  emit('complete');
};

const onDismiss = () => {
  if (isBusy.value) return;
  emit('dismiss');
};
</script>
