export function mapSeverity(key) {
  const dict = {
    NCMinor: 'Non-Compliance (Minor)',
    NCMajor: 'Non-Compliance (Major)',
    NC: 'Non-Compliance',
    Cat1: 'Cat 1',
    Observation: 'Observation',
    Pass: 'Pass',
    NotDetermined: 'Not Determined',
  };
  return dict[key] || key;
}

export function questionHasAnswer(question) {
  if (question?.type === 'File') {
    try {
      const answer = JSON.parse(question?.answer);
      return !!answer?.href || !!answer?.caption;
    } catch (e) {
      // no need to do anything, the answer is not valid JSON
    }
  }
  return !!question?.answer;
}
