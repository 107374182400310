<template>
  <div class="py-3 px-3 d-flex justify-content-between align-items-center">
    <div class="form-label mb-0">{{ audits.length }} audit{{ audits.length === 1 ? '' : 's' }}</div>
    <StatusBadge :status="status" class="" />
  </div>
  <div v-if="props.noaccess" class="border-top">
    <div class="audit-section">
      <FormWrapper label="">            
        <div class="d-flex flex-column gap-2">

          <div class="d-flex justify-content-between align-items-center">
            <span class="form-label mb-0">No Access</span>
            <span class="text-secondary fs-xxs">Yes</span>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <span class="form-label mb-0">Comment</span>
            <span class="text-secondary fs-xxs">{{ noAccessAudit.comment }}</span>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <span class="form-label mb-0">Audit Date</span>
            <span class="text-secondary fs-xxs">{{ formatISOStringDate(noAccessAudit.auditDate) }}</span>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <span class="form-label mb-0">Created By</span>
            <span class="text-secondary fs-xxs">{{ noAccessAudit.createdBy }}</span>
          </div>

          <button
            class="btn btn-outline-danger w-100"
            type="button"
            @click.prevent="emit('removeNoAccess')"
          >
            Remove No Access
          </button>
          <button
            class="btn btn-primary w-100"
            type="button"
            @click.prevent="emit('resolveNoAccess')"
          >
            Resolve No Access
          </button>
        </div>
      </FormWrapper>
    </div>
  </div>
  <div v-if="escalatedToAssurance" class="border-top">
    <div class="audit-section">
      <FormWrapper label="">
        <div class="d-flex flex-column gap-2">
          <div class="d-flex justify-content-between align-items-center">
            <span class="form-label mb-0">Escalated to Assurance</span>
            <span class="text-secondary fs-xxs">Yes</span>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <span class="form-label mb-0">Comment</span>
            <span class="text-secondary fs-xxs">{{ escalatedToAssuranceAudit.comment }}</span>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <span class="form-label mb-0">Audit Date</span>
            <span class="text-secondary fs-xxs">{{ formatISOStringDate(escalatedToAssuranceAudit.auditDate) }}</span>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <span class="form-label mb-0">Created By</span>
            <span class="text-secondary fs-xxs">{{ escalatedToAssuranceAudit.createdBy }}</span>
          </div>
          <button
            class="btn btn-outline-danger w-100"
            type="button"
            @click.prevent="emit('removeEscalateToAssurance')"
          >
            Remove Escalate to Assurance
          </button>
          <button
            class="btn btn-primary w-100"
            type="button"
            @click.prevent="emit('resolveEscalateToAssurance')"
          >
            Resolve Escalate to Assurance
          </button>
        </div>
      </FormWrapper>
    </div>
  </div>  
  <AuditDesktopSummarySection v-for="section of noAccessOrEscalatedSections" :key="section.name" v-bind="section" />
  <AuditDesktopSummarySection v-for="section of sections" :key="section.name" v-bind="section" />
  <AuditDesktopSummarySection
    v-for="section of measureSections"
    :key="section.title"
    v-bind="section"
  />
</template>

<script setup>
import { computed } from 'vue';
import StatusBadge from './StatusBadge.vue';
import AuditDesktopSummarySection from './AuditDesktopSummarySection.vue';
import FormWrapper from './FormWrapper.vue';
import { formatISOStringDate } from '../utilities/date';

const emit = defineEmits(['removeNoAccess', 'resolveNoAccess', 'removeEscalateToAssurance', 'resolveEscalateToAssurance']);

const props = defineProps({
  audits: {
    type: Array,
    required: true,
  },
  auditableSections: {
    type: Object,
    required: true,
  },
  project: {
    type: Object,
    required: true,
  },
  noaccess: {
    type: Boolean,
    default: false,
  },
  escalatedToAssurance: {
    type: Boolean,
    default: false,
  },
});

const sections = computed(() =>
  props.auditableSections
    .filter((s) => s.summary)
    .map((s) => ({
      title: s.title,
      link: s.link,
      audits: props.audits.filter((audit) => audit.auditingWhat === s.name),
    }))
);

const noAccessOrEscalatedSections = computed(() => {
  const noAccess = props.audits.filter((audit) => audit.auditingWhat === 'noaccess' && audit.isResolved).map((audit) => ({
    ...audit,
    outcome: 'Resolved',
    noChevron: true,
  }));
  const escalatedToAssurance = props.audits.filter((audit) => audit.auditingWhat === 'escalated-to-assurance' && audit.isResolved).map((audit) => ({
    ...audit,
    outcome: 'Resolved',
    noChevron: true,
  }));

  const sections = [];
  if (noAccess.length) {
    sections.push({
      title: 'No Access History',
      link: null,
      audits: noAccess,
    });
  }
  if (escalatedToAssurance.length) {
    sections.push({
      title: 'Escalated to Assurance History',
      link: null,
      audits: escalatedToAssurance,
    });
  }
  return sections;
});

const noAccessAudit = computed(() => {
  return props.audits.find((audit) => audit.auditingWhat === 'noaccess' && !audit.isResolved);
});

const escalatedToAssuranceAudit = computed(() => {
  return props.audits.find((audit) => audit.auditingWhat === 'escalated-to-assurance' && !audit.isResolved);
});

const measureSections = computed(() => {
  const standalone = props.project.lodgementOfWorkStyle === 'Standalone';
  if (!standalone) {
    const planMeasures = props.project.mediumTermImprovementPlan.stages.flatMap(
      (stage) => stage.selectedMeasures
    );
    return planMeasures.map((planMeasure) => {
      const findMeasure = (lodgementMeasure) =>
        lodgementMeasure.improvementOptionEvaluationMeasureId ===
        planMeasure.improvementOptionEvaluationMeasureId;
      const lodgement = props.project.lodgements.find(
        (l) => l.isComplete && l.measures.find(findMeasure)
      );
      const measure = lodgement?.measures.find(findMeasure);
      return {
        title: `Measure – ${planMeasure.measureType}`,
        link: lodgement
          ? {
              name: 'lodgement-measure',
              params: { lodgementId: lodgement.lodgementId, measureId: measure?.measureId },
              query: {
                returnTo: 'project-lodgements',
              },
            }
          : null,
        audits: props.audits.filter(
          (audit) =>
            audit.auditingWhat === 'lodgement-measure' && audit.whatMeasureId === measure?.measureId
        ),
      };
    });
  } else {
    const allMeasures = props.project.lodgements.flatMap((lod) => lod.measures);
    return allMeasures.map((planMeasure) => {
      const findMeasure = (lodgementMeasure) =>
        lodgementMeasure.measureId === planMeasure.measureId;
      const lodgement = props.project.lodgements.find(
        (l) => l.isComplete && l.measures.find(findMeasure)
      );
      const measure = lodgement?.measures.find(findMeasure);
      return {
        title: `Measure – ${planMeasure.measureType}`,
        link: lodgement
          ? {
              name: 'lodgement-measure',
              params: { lodgementId: lodgement.lodgementId, measureId: measure?.measureId },
              query: {
                returnTo: 'project-lodgements',
              },
            }
          : null,
        audits: props.audits.filter(
          (audit) =>
            audit.auditingWhat === 'lodgement-standalone-measure' &&
            audit.whatMeasureId === measure?.measureId
        ),
      };
    });
  }
});

const status = computed(() => {
  if (!props.audits.length) return 'empty';
  let status = 'complete';
  for (const audit of props.audits) {
    if (!audit.isComplete) {
      status = 'ongoing';
      break;
    }
    if (audit.isComplete && !(audit.isResolved || audit.isPass || audit.outcome === 'Pass')) {
      status = 'incomplete';
    }
  }
  if (props.noaccess) {
    status = 'noaccess';
  }
  if (props.escalatedToAssurance) {
    status = 'escalated-to-assurance';
  }
  return status;
});
</script>
