<template>
  <div id="measureList">
    <div v-if="sortedItems?.length">
      <div v-for="(item, i) of sortedItems" :key="item[props.itemKey]">
        <div v-for="(g, idx) of props.groupBy" :key="g">
          <h6
            v-if="
              (props.groupBy.length - 1 > idx && availableGroupBys(g).length > 1 && item[g] !== sortedItems[i - 1]?.[g])
              || (i === 0 && idx === 0) // Always show the first group
            "
            class="my-2"
          >
            <router-link
              class="mt-2"
              v-if="props.groupByLink && g in props.groupByLink"
              :to="item[props.groupByLink[g]]"
              >{{ addSpaceToCamel(item[g]) }}</router-link
            >
            <div :class="i > 0 ? 'mt-4' : ''" v-else>{{ addSpaceToCamel(item[g]) }}</div>
          </h6>
        </div>
        <div
          class="px-2 py-2 border rounded overflow-hidden"
          :class="{ 'bg-light': i % 2, 'border-bottom': i < sortedItems.length - 1 }"
        >
          <div class="d-flex align-items-center" :class="{'flex-column': props.buttonAlignment === 'column'}">
            <slot name="item" :item="item" :index="i" />

            <div class="ms-auto d-flex align-items-center flex-shrink-0">
              <slot
                name="actions"
                :item="item"
                :index="i"
                :can-edit="canEdit(i)"
                :can-remove="canRemove(i)"
                button-class="btn btn-sm rounded-circle border-0"
              />
              <button
                v-if="canEdit(i) || !hideView"
                class="btn btn-sm rounded-circle btn-outline-primary border-0"
                type="button"
                @click.prevent="showEditModal(i, item)"
              >
                <i :class="canEdit(i) ? 'bi-pencil' : 'bi-ticket-detailed'" />
              </button>
              <button
                v-if="canRemove(i)"
                class="btn btn-sm rounded-circle btn-outline-danger border-0"
                type="button"
                @click.prevent="showRemoveModal(i, item)"
              >
                <i class="bi bi-trash" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="canAdd" class="d-flex" :class="{ 'mt-4': sortedItems.length }">
      <slot name="add" :show-modal="showAddModal" :label="props.label">
        <button
          class="btn btn-sm btn-outline-primary"
          type="button"
          @click.prevent="showAddModal()"
        >
          Add {{ props.label }}
        </button>
      </slot>
      <slot name="add-extra" :show-modal="showAddModal" />
    </div>

    <Teleport to="body">
      <Modal
        ref="editModalEl"
        :title="modalTitle"
        :on-save="onSubmit"
        :disabled="!canEdit(editIndex) && editIndex !== undefined"
      >
        <template #form="{ disabled }">
          <form @submit.prevent="onSubmit" :disabled="disabled">
            <slot
              name="form"
              :is-adding="editIndex === undefined"
              :is-editing="editIndex !== undefined"
              :disabled="disabled"
            />
          </form>
        </template>
        <template #footer>
          <slot name="footer" />
        </template>
      </Modal>
    </Teleport>
    <Teleport to="body">
      <Modal
        ref="removeModalEl"
        title="Confirm Removal"
        save-text="Remove"
        :on-save="remove"
        danger
      >
        <template #form>Are you sure?</template>
      </Modal>
    </Teleport>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import Modal, { useModal } from './Modal.vue';
import { addSpaceToCamel } from '../utilities/case';

const emit = defineEmits([
  'add',
  'edit',
  'remove',
  'showAdd',
  'showEdit',
  'showRemove',
  'showAddComplete',
]);

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  itemKey: {
    type: String,
    required: true,
  },
  canAdd: {
    type: Boolean,
    default: false,
  },
  canEdit: {
    type: [Boolean, Function],
    default: false,
  },
  canRemove: {
    type: [Boolean, Function],
    default: false,
  },
  hideView: {
    type: Boolean,
    default: false,
  },
  shouldShowAdd: {
    type: Boolean,
    default: false,
  },
  addType: {
    type: String,
    default: '',
  },
  groupBy: {
    type: Array,
    default: () => [],
  },
  groupByLink: {
    type: Object,
    default: () => ({}),
  },
  buttonAlignment: {
    type: String,
    default: 'row',
  },
});

const canEdit = (index) => {
  if (typeof props.canEdit === 'function') {
    const item = props.items[index];
    return props.canEdit(item);
  }
  return props.canEdit;
};

const canRemove = (index) => {
  if (typeof props.canRemove === 'function') {
    const item = props.items[index];
    return props.canRemove(item);
  }
  return props.canRemove;
};

// Modal
const editIndex = ref(undefined);
const editItem = ref(null);
const removeIndex = ref(undefined);
const removeItem = ref(null);

const { modal: editModal, el: editModalEl } = useModal();
const { modal: removeModal, el: removeModalEl } = useModal();

const availableGroupBys = (groupKey) => {
  if (props.groupBy.length === 0) {
    return [];
  }
  return props.items.reduce((p, c) => {
    if (!p.includes(c[groupKey])) {
      p.push(c[groupKey]);
    }
    return p;
  }, []);
};

const sortedItems = computed(() => {
  const items = [...props.items];

  // specific ordering for stages
  const stageOrder = ['Assessment', 'ProjectCreation', 'Design', 'LodgementStage', 'Measure', 'ProjectCompletion']

  items.sort((a, b) => {
    for (const g of props.groupBy) {
      if (g === 'stage') {
        if (stageOrder.indexOf(a[g]) < stageOrder.indexOf(b[g])) {
          return -1;
        }
        if (stageOrder.indexOf(a[g]) > stageOrder.indexOf(b[g])) {
          return 1;
        }
      }
      if ((a[g] || '') < (b[g] || '')) {
        return -1;
      }
      if ((a[g] || '') > (b[g] || '')) {
        return 1;
      }
    }
    return 0;
  });

  return items;
});

watch(
  () => props.shouldShowAdd,
  () => {
    if (props.shouldShowAdd) {
      showAddModal({ type: props.addType });
      emit('showAddComplete');
    }
  }
);

const modalTitle = ref('Edit');

const showAddModal = (value) => {
  editIndex.value = undefined;
  editItem.value = null;
  emit('showAdd', {
    value,
    next(title = 'Add') {
      modalTitle.value = title;
      editModal.value.show();
    },
  });
};

const showEditModal = (index, item) => {
  editIndex.value = index;
  editItem.value = item;
  emit('showEdit', {
    index,
    item,
    next(title = 'Edit') {
      modalTitle.value = title;
      editModal.value.show();
    },
  });
};

const showRemoveModal = (index, item) => {
  removeIndex.value = index;
  removeItem.value = item;
  removeModal.value.show();
  emit('showRemove', {
    index,
    item,
  });
};

const remove = () => {
  emit('remove', {
    index: removeIndex.value,
    item: removeItem.value,
    next: () => removeModal.value.hide(),
  });
};

const onSubmit = async () => {
  if (editIndex.value === undefined) {
    emit('add', {
      next: () => editModal.value.hide(),
    });
  } else {
    emit('edit', {
      index: editIndex.value,
      item: editItem.value,
      next: () => editModal.value.hide(),
    });
  }
};
</script>
