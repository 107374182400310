import { useLocalStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { formatAssessmentAddressSingleLine } from '../utilities/address';
import { recentSerializer } from '../utilities/recent';

export const idKey = 'retrofitAssessmentId';

export const toRecent = (assessment) => {
  const id = assessment[idKey];
  const title =
    assessment.retrofitReference || formatAssessmentAddressSingleLine(assessment) || '-';
  const status = assessment.isComplete ? 'Complete' : 'InProgress';
  const to = { name: 'assessment', params: { assessmentId: id } };
  return { id, title, status, to };
};

export const useAssessmentsStore = defineStore({
  id: 'assessments',
  state: () => ({
    assessment: null,
    assessments: null,
    assessmentCache: {},
    searchParams: {
      term: '',
      pageSize: 12,
      page: 0,
      totalPages: 0,
      totalItems: 0,
    },
    recentlyViewed: useLocalStorage('assessments-recent', [], {
      serializer: recentSerializer,
    }),
  }),
  getters: {
    isComplete: (state) => state.assessment.isComplete,
  },
  actions: {
    // Individual
    get(id) {
      return this.assessmentCache[id];
    },
    cache(id, assessment) {
      this.assessmentCache[id] = assessment;
      this.refreshRecents([assessment]);
      return this;
    },
    set(id) {
      const cached = this.assessmentCache[id];
      if (!cached) throw new Error(`Assessment with ID “${id}” not found`);
      this.assessment = cached;
      return this;
    },
    unset() {
      this.assessment = null;
      return this;
    },
    unlist(id) {
      this.assessments = this.assessments?.filter((assessment) => assessment[idKey] !== id) || null;
      return this;
    },
    uncache(id) {
      delete this.assessmentCache[id];
      return this;
    },
    remove(id) {
      this.uncache(id);
      this.unlist(id);
      this.removeRecent(id);
      return this;
    },
    // List
    setList(assessments) {
      this.assessments = assessments;
      this.refreshRecents(assessments);
      return this;
    },
    // Recent
    addRecent(id) {
      const assessment = this.get(id);
      if (assessment) {
        // If it already exists in the array, remove it
        this.removeRecent(id);
        // Push entry to the start of the array
        this.recentlyViewed.unshift(toRecent(assessment));
        // Limit the number of results
        const maxLength = 3;
        this.recentlyViewed.length = Math.min(this.recentlyViewed.length, maxLength);
      }
      return this;
    },
    removeRecent(id) {
      const index = this.recentlyViewed.findIndex((recent) => recent.id === id);
      if (index > -1) this.recentlyViewed.splice(index, 1);
      return this;
    },
    refreshRecents(assessments) {
      this.recentlyViewed.forEach((recent) => {
        const match = assessments.find((a) => a[idKey] === recent.id);
        if (match) recent = toRecent(match);
      });
      return this;
    },
    clearRecents() {
      this.recentlyViewed.splice(0);
    },
  },
});
