<template>
  <DrawerOverlay title="Book an Appointment" @dismiss="emit('dismiss')">
    <form @submit.prevent="onSubmit">
      <div class="audit-section">
        <FormDate id="date" label="Date" :validator="v$.date" v-model="form.date" />
        <FormInput
          class="mt-3"
          id="time"
          label="Time"
          type="time"
          :validator="v$.time"
          :step="15 * 60"
          v-model="form.time"
        />
        <div class="mt-3 d-flex flex-column gap-2">
          <button class="btn btn-primary" type="submit">Confirm Appointment</button>
          <button
            v-if="props.datetime"
            class="btn btn-outline-secondary"
            type="button"
            @click="emit('confirm', {})"
          >
            Reset Appointment
          </button>
          <button
            v-if="props.datetime"
            class="btn btn-outline-danger"
            type="button"
            @click="showConfirmModal(true, false)"
          >
            Customer Cancelled
          </button>
          <button
            v-if="props.datetime"
            class="btn btn-outline-danger"
            type="button"
            @click="showConfirmModal(false, true)"
          >
            TrustMark Cancelled
          </button>
        </div>
      </div>
    </form>
  </DrawerOverlay>
  <Teleport to="body">
    <Modal ref="confirmModalEl" title="Cancel Appointment" save-text="Cancel Appointment">
      <template #form>Are you sure you want to cancel the appointment?</template>
      <template #footer>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button
          type="button"
          class="btn btn-primary"
          @click.prevent="
            emit('confirm', { customerCancelled, trustMarkCancelled });
            confirmModal.value?.show();
          "
        >
          Cancel Appointment
        </button>
      </template>
    </Modal>
  </Teleport>
</template>

<script setup>
import { ref } from 'vue';
import { addMonths, format, isWithinInterval, parseISO, startOfToday, subMonths } from 'date-fns';
import DrawerOverlay from './DrawerOverlay.vue';
import FormDate from './FormDate.vue';
import FormInput from './FormInput.vue';
import { helpers, required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import Modal, { useModal } from './Modal.vue';

const emit = defineEmits(['confirm', 'dismiss']);
const props = defineProps({
  datetime: {
    type: String,
    default: '',
  },
});

const { modal: confirmModal, el: confirmModalEl } = useModal();

const customerCancelled = ref(false);
const trustMarkCancelled = ref(false);

const showConfirmModal = (cc, tmc) => {
  customerCancelled.value = cc;
  trustMarkCancelled.value = tmc;
  confirmModal.value?.show();
};

const rules = {
  date: {
    required: helpers.withMessage('Date is required', required),
    isWithinRange: helpers.withMessage(
      'The date must be no more than nine months in the past and six months in the future.',
      (value) => {
        if (value) {
          const today = startOfToday();
          const nineMonthsAgo = subMonths(today, 9);
          const inSixMonths = addMonths(today, 6);
          const parsed = parseISO(value);
          return isWithinInterval(parsed, {
            start: nineMonthsAgo,
            end: inSixMonths,
          });
        }
        return true;
      }
    ),
  },
  time: { required: helpers.withMessage('Time is required', required) },
};

const parsed = props.datetime ? parseISO(props.datetime) : null;
const form = ref({
  date: parsed ? format(parsed, "yyyy-MM-dd'T00:00:00'") : null,
  time: parsed ? format(parsed, 'HH:mm') : null,
});

const v$ = useVuelidate(rules, form, { $stopPropagation: true });

const onSubmit = async () => {
  const valid = await v$.value.$validate();
  if (valid) {
    emit('confirm', form.value);
  }
};
</script>
