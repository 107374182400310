<template>
  <DrawerOverlay @dismiss="emit('dismiss')">
    <template #header>
      <div class="btn-group btn-group-sm">
        <input class="btn-check" type="radio" name="all" id="all" value="all" v-model="filter" />
        <label class="btn btn-outline-primary lh-sm" for="all"
          >All <span class="fs-xxs">({{ mappedQuestions.length }})</span></label
        >

        <input
          class="btn-check"
          type="radio"
          name="unanswered"
          id="unanswered"
          value="unanswered"
          v-model="filter"
        />
        <label class="btn btn-outline-primary lh-sm" for="unanswered"
          >Unresolved <span class="fs-xxs">({{ unansweredQuestions.length }})</span></label
        >
      </div>
    </template>
    <div class="d-flex flex-column fs-6 lh-sm pt-2">
      <button
        v-for="q of questions"
        :key="q.questionId"
        class="btn d-flex align-items-center gap-3 bg-white px-3 py-2 text-start text-secondary border-top rounded-0"
        @click.prevent="emit('goto', q.index)"
      >
        <div>{{ q.number }}. {{ q.text }}</div>
        <i
          class="ms-auto fs-5"
          :class="q.answered ? 'bi-check-circle-fill text-success' : 'bi-circle text-secondary'"
        />
      </button>
    </div>
  </DrawerOverlay>
</template>

<script setup>
import { computed, ref } from 'vue';
import DrawerOverlay from './DrawerOverlay.vue';
import { questionHasAnswer } from '../utilities/compliance';

const emit = defineEmits(['dismiss', 'goto']);
const props = defineProps({
  questions: {
    type: Array,
    required: true,
  },
});

const filter = ref('all');

const mappedQuestions = computed(() =>
  props.questions.map((q, i) => ({
    index: i,
    id: q.questionId,
    number: i + 1,
    text: q.text,
    answered: questionHasAnswer(q),
  }))
);

const unansweredQuestions = computed(() => mappedQuestions.value.filter((q) => !q.answered));

const questions = computed(() =>
  filter.value === 'unanswered' ? unansweredQuestions.value : mappedQuestions.value
);
</script>
