<template>
  <div class="form-group" :class="{ required: isRequired, disabled }">
    <div class="d-flex align-items-top">
      <label v-if="props.label" :for="props.id" class="form-label">{{ props.label }}</label>
      <FormPopover v-if="props.popover" :content="props.popover" />
    </div>
    <div class="d-flex align-items-top">
      <label v-if="props.sublabel" :for="props.id" class="form-label">{{ props.sublabel }}</label>
    </div>
    <div class="input-group" :class="{ 'has-validation': !!error }">
      <select
        :id="id"
        class="form-select"
        :class="{ 'is-invalid': error }"
        :disabled="disabled || !options.length"
        @change="onChange"
      >
        <option selected disabled value="">
          {{ caption }}
        </option>
        <template v-if="optionsInGroups">
          <optgroup v-for="(optionsGroup, title) of optionsInGroups" :label="title" :key="title">
            <option v-for="option of optionsGroup" :key="option.key" :value="option.index">
              {{ option.title }}
            </option>
          </optgroup>
        </template>
        <template v-else>
          <option v-for="option of options" :key="option.key" :value="option.index">
            {{ option.title }}
          </option>
        </template>
      </select>
      <div v-if="error" class="invalid-feedback">{{ error }}</div>
    </div>
    <div v-for="(option, idx) of selected" :key="option.key" class="selected-option">
      <div class="flex-grow-1 small lh-sm">{{ option.title }}</div>
      <button
        v-if="!disabled"
        class="btn btn-link flex-shrink-0 ms-1"
        @click.prevent="removeOption(idx)"
      >
        <i class="bi bi-x-lg" />
      </button>
    </div>
    <div v-if="description" class="mt-1 text-secondary">
      <small>{{ description }}</small>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { inputProps, inputEmits, useFormInput } from '../utilities/form';
import FormPopover from './FormPopover.vue';

const emit = defineEmits(inputEmits);
const props = defineProps({
  ...inputProps,
  modelValue: {
    type: Array,
    default: () => [],
  },
  options: {
    type: Array,
    default: () => [],
  },
  optionKey: {
    type: String,
    default: '',
  },
  optionTitle: {
    type: [String, Function],
    default: '',
  },
  groupOptionsBy: {
    type: String,
    default: '',
  },
  sublabel: {
    type: String,
    default: '',
  },
});

const { value, isRequired, error } = useFormInput(props, emit);

const getTitle = (option) => {
  if (props.optionTitle === '') {
    return option;
  }
  if (typeof props.optionTitle === 'string') {
    return option[props.optionTitle];
  }
  if (typeof props.optionTitle === 'function') {
    return props.optionTitle(option);
  }
  return '';
};

const getKey = (option) => {
  if (props.optionKey === '') {
    return option;
  }
  if (typeof props.optionKey === 'string') {
    return option?.[props.optionKey] || '';
  }
  if (typeof props.optionKey === 'function') {
    return props.optionKey(option);
  }
  return '';
};

const selected = computed(() =>
  (props.modelValue || []).map((option) => ({
    key: getKey(option),
    title: getTitle(option),
  }))
);

const selectedKeys = computed(() => selected.value.map((option) => option.key));
const mapOption = (option, index) => ({
  index,
  key: getKey(option),
  title: getTitle(option),
});
const optionIsUnselected = (mappedOption) => !selectedKeys.value.includes(mappedOption.key);

const options = computed(() => {
  return (props.options || []).map(mapOption).filter(optionIsUnselected);
});

const optionsInGroups = computed(() => {
  if (!props.groupOptionsBy) return null;
  return (props.options || []).reduce((acc, option, index) => {
    const mapped = mapOption(option, index);
    if (optionIsUnselected(mapped)) {
      const groupKey = option[props.groupOptionsBy];
      if (acc[groupKey]) acc[groupKey].push(mapped);
      else acc[groupKey] = [mapped];
    }
    return acc;
  }, {});
});

const caption = computed(() => {
  if (props.disabled) {
    const { length } = selected.value;
    return `${length} option${length === 1 ? '' : 's'} selected`;
  }
  const { length } = options.value;
  return `${length} option${length === 1 ? '' : 's'} available`;
});

const onChange = (e) => {
  const selected = props.options[e.target.value];
  e.target.value = '';
  value.value = [...(value.value || []), selected];
};

const removeOption = (index) => {
  value.value = value.value.filter((_, idx) => idx !== index);
};
</script>

<style lang="scss">
.selected-option {
  display: flex;
  align-items: center;
  border-width: 1px;
  border-style: dashed;
  border-color: $primary;
  color: $primary;
  padding: $input-padding-y $input-padding-x;
  margin-top: 0.25em;
  border-radius: $border-radius;
  .btn-link {
    transition: none;
    padding: 0;
  }
  &:hover {
    border-style: solid;
    background-color: $primary;
    color: white;
    .btn-link {
      color: white;
    }
  }
}

.disabled {
  .selected-option {
    border-style: solid;
    color: inherit;
    border-color: $input-disabled-border-color;
    &:hover {
      color: inherit;
      background-color: inherit;
      border-style: solid;
    }
  }
}
</style>
