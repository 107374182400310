<template>
  <div class="nav-account" ref="target">
    <div class="py-2 px-3">
      <div class="d-flex justify-content-start align-items-center gap-3">
        <div class="nav-account-icon bi-person-circle"></div>
        <div class="overflow-hidden py-2">
          <div v-if="accountHolderName" class="fw-bold text-truncate">{{ accountHolderName }}</div>
          <div class="small text-truncate">{{ email }}</div>
          <RouterLink
            class="small text-decoration-none"
            :to="{ name: 'account' }"
            @click="emit('dismiss')"
            >View Account</RouterLink
          >
        </div>
      </div>
    </div>
    <a
      v-if="availableAccounts?.length"
      href="#"
      @click.prevent="showAccounts"
      class="nav-account-link"
    >
      <span
        >Change Account
        <i class="bi available-accounts fs-5 ms-2" :class="availableAccountsNumberClass"
      /></span>
      <i class="bi-shuffle" />
    </a>
    <div v-if="showingAccounts" class="nav-account-accounts">
      <a
        v-for="account of availableAccounts"
        :key="account.retrofitAccountId"
        class="nav-account-link"
        href="#"
        @click.prevent="switchAccount(account)"
      >
        <span class="d-flex align-items-center gap-2">
          <i class="bi-box-arrow-in-right" />
          <span class="d-flex flex-column gap-1">
            <span class="">{{ account.accountHolderName }}</span>
            <span class="fs-xxs fw-normal">TMLN: {{ account.tmln }}</span>
          </span>
        </span>
      </a>
    </div>
    <RouterLink class="nav-account-link" :to="{ name: 'logout' }"
      >Logout<i class="bi-box-arrow-right" />
    </RouterLink>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { onClickOutside } from '@vueuse/core';
import { useAccountStore } from '../stores/account';
import { useAppStore } from '../stores/app';
import { useAssessmentsStore } from '../stores/assessments';
import { useAuthStore } from '../stores/auth';
import { useLodgementsStore } from '../stores/lodgements';
import { useProjectsStore } from '../stores/projects';
import { useResponsesStore } from '../stores/responses';

const accountStore = useAccountStore();
const authStore = useAuthStore();
const appStore = useAppStore();

const emit = defineEmits(['dismiss']);
const props = defineProps({
  dismissIgnore: {
    type: Array,
    default: () => [],
  },
});

const target = ref(null);
onClickOutside(target, (event) => emit('dismiss', event), { ignore: props.dismissIgnore });

const accountHolderName = computed(() => accountStore.account?.accountHolderName || 'TrustMark');

const email = computed(() => authStore.account?.accountName);

const availableAccounts = computed(() => {
  const currentAccountId = accountStore.account?.retrofitAccountId;
  return authStore.account?.availableRetrofitAccountIdentities?.filter(
    (account) => account.retrofitAccountId !== currentAccountId
  );
});

const availableAccountsNumberClass = computed(() => {
  const currentAccountId = accountStore.account?.retrofitAccountId;
  const accounts = authStore.account?.availableRetrofitAccountIdentities?.filter(
    (account) => account.retrofitAccountId !== currentAccountId
  );
  return `bi-${accounts.length > 9 ? 9 : accounts.length}-circle-fill`;
});

const showingAccounts = ref(false);
const showAccounts = () => {
  showingAccounts.value = !showingAccounts.value;
};
const router = useRouter();
const switchAccount = async (account) => {
  await authStore.switchAccount(account.retrofitAccountId);
  router.push({ name: 'dashboard' });
  emit('dismiss');
  useProjectsStore().$reset();
  useAssessmentsStore().$reset();
  useLodgementsStore().$reset();
  useResponsesStore().$reset();
  await accountStore.init();
  await appStore.init();
  appStore.addNotification({
    type: 'success',
    message: `Changed to ${account.accountHolderName}.`,
    timeout: 5000,
  });
};
</script>

<style lang="scss" scoped>
.nav-account-icon {
  color: $primary;
  font-size: 3rem;
}
.nav-account {
  background-color: $white;
  position: absolute;
  right: 0;
  top: $navbar-height;
  box-shadow: $box-shadow;
  width: $drawer-width;
  line-height: $line-height-sm;
}

.nav-account-link,
.nav-account-account {
  text-decoration: none;
  padding: 0.75rem 1rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: $btn-font-size;
  font-weight: $btn-font-weight;
}
.nav-account-link {
  color: $secondary;
  background-color: $light;
  border-top: 1px solid $gray-300;

  &:hover,
  &:focus {
    color: $primary;
  }
}

.nav-account-accounts {
  max-height: 12rem;
  overflow-y: scroll;
  .nav-account-link {
    color: $gray-700;
    background-color: $gray-300;
    border-top: 1px solid $gray-400;
    &:first-of-type {
      border-top-color: $gray-300;
    }
    &:hover,
    &:focus {
      color: $white;
      background-color: $primary;
    }
  }
}

.available-accounts {
  color: $primary;
}
</style>
