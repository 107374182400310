<template>
  <button class="btn w-100 btn-primary" :disabled="busy">
    <slot />
    <span v-if="busy" class="ms-1 spinner-border spinner-border-sm align-text-bottom" />
  </button>
</template>

<script setup>
import { inject } from 'vue';

const busy = inject('busy');
</script>
