import { format, parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export const dateToISOString = (date) => {
  // Format to specific timezone instead of local
  // Instead of using date-fns "formatISO"
  return format(date, "yyyy-MM-dd'T'HH:mm:ss");
};

export const formatISOStringDate = (dateStr, tokens = 'EEE do MMM yyyy') => {
  try {
    const parsed = parseISO(dateStr);
    return formatDate(parsed, tokens);
  } catch {
    return '';
  }
};

export const formatDate = (date, tokens = 'EEE do MMM yyyy') => {
  try {
    return formatInTimeZone(date, 'Europe/London', tokens);
  } catch {
    return '';
  }
};
